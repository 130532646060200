import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FaceIcon from '@material-ui/icons/Face';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import PanoramaIcon from '@material-ui/icons/Panorama';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PhoneIcon from '@material-ui/icons/Phone';

import LockIcon from '@material-ui/icons/Lock';
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);


const HeaderLinks = ({ isAuthenticated, role }) => {
  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [classicModal, setClassicModal] = React.useState(false);
  const [classicModal2, setClassicModal2] = React.useState(false);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  
  return (
   <div className={classes.boxRight}>
     <List className={classes.list}>
       <ListItem className={classes.listItem}>
         <a className={classes.link} href="tel:+375 (29) 847-98-34">
           <Button color="transparent" round className={classes.navButton}>
             <PhoneIcon className={classes.icons} />
             +375 (29) 847-98-34
           </Button>
         </a>
         <a className={classes.link} href="tel:+375 (25) 502-08-54">
           <Button color="transparent" round className={classes.navButton}>
             <PhoneIcon className={classes.icons} />
             +375 (25) 502-08-54
           </Button>
         </a>
       </ListItem>
       <ListItem className={classes.listItem}>
         {isAuthenticated
           ?
           <Link to={role==='Admin' ? "/administrator" : "/profile"} className={classes.link}>
             <Button color="rose" round className={classes.navButton}>
               <FaceIcon className={classes.icons} />
               {role==='Admin' ? 'Администратор' : 'Профиль' }
             </Button>
           </Link>
           :
           <Link to="/login" className={classes.link}>
             <Button color="rose" round className={classes.navButton}>
               <LockIcon className={classes.icons} />
               Вход
             </Button>
           </Link>
         }
       </ListItem>
     </List>
     
     <List className={classes.list}>
       
       <ListItem className={classes.listItem}>
         <Button
           aria-controls="simple-menu"
           aria-haspopup="true"
           color="transparent"
           round
           className={classes.navLink}
           onClick={handleClick}
         >
           выпускные альбомы
         </Button>
         <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
           <MenuItem>
             <Link to={"/kindergarten"} className={classes.link}>
               Для детского сада
            </Link> 
           </MenuItem>
           <MenuItem onClick={handleClose}>
             <Link to={"/jonior-school"} className={classes.link}>
               Для начальной школы
             </Link>
            </MenuItem>
           <MenuItem onClick={handleClose}>
             <Link to={"/high-school"} className={classes.link}>
               Для средней школы
             </Link>
           </MenuItem>
         </Menu>
       </ListItem>
       <ListItem className={classes.listItem}>
         <Link to={"/constructor"} className={classes.link}>
           <Button
             color="transparent"
             round
             className={classes.navLink}
           >
             <AddPhotoAlternateIcon className={classes.icons} />
             ФОТОАЛЬБОМЫ
           </Button>
         </Link>
       </ListItem>
       <ListItem className={classes.listItem}>
         <Link to={"/canvas"} className={classes.link}>
           <Button
             color="transparent"
             round
             className={classes.navLink}
           >
             <PanoramaIcon className={classes.icons} />
             ХОЛСТЫ
           </Button>
         </Link>
       </ListItem>

       <ListItem className={classes.listItem}>
         <Button
           aria-controls="simple-menu2"
           aria-haspopup="true"
           color="transparent"
           round
           className={classes.navLink}
           onClick={handleClick2}
         >
           услуги
         </Button>
         <Menu
           id="simple-menu2"
           anchorEl={anchorEl2}
           keepMounted
           open={Boolean(anchorEl2)}
           onClose={handleClose2}
         >
           <MenuItem>
             <Link to={"/photographer"} className={classes.link}>
               Свадебный фотограф 
             </Link>
           </MenuItem>
           <MenuItem onClick={handleClose2}>
             <Link to={"/designer"} className={classes.link}>
               Услуги дизайнера
             </Link>
           </MenuItem>
           {/*<MenuItem onClick={handleClose2}>*/}
           {/*  <Link to={"/magnet"} className={classes.link}>*/}
           {/*    Печать магнита*/}
           {/*  </Link>*/}
           {/*</MenuItem> */}
           {/*<MenuItem onClick={handleClose2}>*/}
           {/*  <Link to={"/cup"} className={classes.link}>*/}
           {/*    Печать на кружке*/}
           {/*  </Link>*/}
           {/*</MenuItem>*/}
           <MenuItem onClick={handleClose2}>
             <Button
               color="primary"
               block
               size="sm"
               onClick={() => setClassicModal(true)}
             >
               Как оплатить (ЕРИП)
             </Button>
           </MenuItem>
           <MenuItem onClick={handleClose2}>
             <Button
               color="primary"
               block
               size="sm"
               onClick={() => setClassicModal2(true)}
             >
               Способы доставки
             </Button>
           </MenuItem>
         </Menu>
       </ListItem>
      
     </List>
     <Dialog
       classes={{
         root: classes.center,
         paper: classes.modal
       }}
       open={classicModal}
       TransitionComponent={Transition}
       keepMounted
       onClose={() => setClassicModal(false)}
       aria-labelledby="classic-modal-slide-title"
       aria-describedby="classic-modal-slide-description"
     >
       <DialogTitle
         id="classic-modal-slide-title"
         disableTypography
         className={classes.modalHeader}
       >
         <h4 className={classes.modalTitle}>Система «Расчет» (ЕРИП)</h4>
       </DialogTitle>
       <DialogContent
         id="classic-modal-slide-description"
         className={classes.modalBody}
       >
         <p>
           С помощью Системы «Расчет» (ЕРИП):<br/>
           • электронными деньгами (iPay, Webmoney (WMB) и др.);<br/>
           • платежной карточкой (интернет-банк, мобильный-банк, банкомат, инфокиоск, касса банка и Белпочты*);<br/>
           • наличными (устройство cash-in, касса банка и Белпочты*).<br/>
           <br/>
           * если Вы осуществляете платеж в кассе банка или Белпочты, пожалуйста, сообщите кассиру о
           необходимости проведения оплаты услуги через систему «Расчет» (ЕРИП).
           <br/><br/>
           Расположение услуги в дереве ЕРИП:<br/>

           - Система «Расчет» (ЕРИП)<br/>

           - Фото, видео, полиграфия, реклама<br/>

           - Могилевская обл.<br/>

           - ИП Сотниченко Ю.А.<br/>

           ► Фото-видео услуги<br/><br/>

           Нахождение услуги в дереве услуг системы «Расчет» (ЕРИП) в некоторых пунктах оплаты может отличаться
           от описанного выше. В связи с этим, в случае возникновения проблем с поиском услуги, предлагаем
           выполнить поиск по УНП: 791233325.
         </p>
       </DialogContent>
       <DialogActions className={classes.modalFooter}>
         <Button
           onClick={() => setClassicModal(false)}
           color="primary"
           size="sm"
         >
           Закрыть
         </Button>
       </DialogActions>
     </Dialog>
     <Dialog
       classes={{
         root: classes.center,
         paper: classes.modal
       }}
       open={classicModal2}
       TransitionComponent={Transition}
       keepMounted
       onClose={() => setClassicModal2(false)}
       aria-labelledby="classic-modal-slide-title"
       aria-describedby="classic-modal-slide-description"
     >
       <DialogTitle
         id="classic-modal-slide-title"
         disableTypography
         className={classes.modalHeader}
       >
         <h4 className={classes.modalTitle}>Способы доставки</h4>
       </DialogTitle>
       <DialogContent
         id="classic-modal-slide-description"
         className={classes.modalBody}
       >
         <p>

           Курьером по г.Минску,г.Могилёву (бесплатно).<br/>
           Почтой, за счет покупателя(пересылка бесплатно при заказе от 100 руб)
         </p>
       </DialogContent>
       <DialogActions className={classes.modalFooter}>
         <Button
           onClick={() => setClassicModal2(false)}
           color="primary"
           size="sm"
         >
           Закрыть
         </Button>
       </DialogActions>
     </Dialog>
   </div>
  );
}

HeaderLinks.propTypes = {
    isAuthenticated: PropTypes.bool,
    role: PropTypes.string
};

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    role: state.Auth.role
});


export default connect(mapStateToProps)(HeaderLinks)
