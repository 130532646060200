import React, {useEffect, useState, useRef} from "react";
import {logout, addPhone} from '../../actions/auth'
import {userOrders} from "../../actions/orders"
// nodejs library that concatenates classes
import classNames from "classnames";
import CustomInput from "components/CustomInput/CustomInput.js";
import validator from 'validator'
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

import Header from "../../components/Header/Header.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from "../../components/CustomButtons/Button";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LibraryBooks from "@material-ui/icons/LibraryBooks";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const ProfilePage = (props) => {
  const classes = useStyles();
  const {...rest} = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const { isAuthenticated, auth, history, logout, getOrders, orders, addPhone } = props;
  const inputRef = useRef();
  const [dataOrders, setDataOrders] = useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [phoneModal, setPhoneModal] = React.useState(false);
  const [resourceOrderNumber, setresourceOrderNumber] = React.useState(false);
  const [phone, setPhone] = React.useState(null);

  useEffect(() => {
    if (dataOrders.length == 0 && auth && auth._id) {
      getOrders(auth._id);
    }
    setDataOrders(orders);

  }, [auth, orders]);

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push('/');
    }
  }, [isAuthenticated]);

  useEffect(()=>{
    if (auth.phone !== "")
      setPhone(auth.phone)
  }, [auth.phone])

  const [btnDisable, setBtnDisable] = useState(true);

  const handleChange = () => {
    let number = inputRef.current.children[0].value;
    if (number.length > 8)
    setBtnDisable(!validator.isMobilePhone(number));
    else setBtnDisable(true)
  }

  return (
    <div>
      <Header
        color="transparent"
        brand="A-PRIORI"
        rightLinks={<HeaderLinks/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small
                filter
                image={
                  "https://ic.pics.livejournal.com/galeneastro/32190196/2011009/2011009_900.jpg"
                }
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={auth.avatar} alt="..." className={imageClasses}/>
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Добро пожаловать, {auth.name}</h3>
                    <h5>{auth.email}</h5>
                    {phone ?
                      <a href={'tel: ' + phone}>{phone}</a> :
                      <Button
                        onClick={() => setPhoneModal(true)}
                        color="primary"
                        size="sm"
                      >
                        Добавить телефон
                      </Button>
                    }
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Здесь находится информармация о Ваших заказах и о статусе выполнения
              </p>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>Название</TableCell>
                      <TableCell align="left">Номер заказа</TableCell>
                      <TableCell align="left">Статус</TableCell>
                      <TableCell align="left">Дата заказа</TableCell>
                      <TableCell align="center">Цена</TableCell>
                      <TableCell align="right">Оплата</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataOrders.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell component="th" scope="row">{row.name} {row.size}</TableCell>
                        <TableCell align="left">Номер №{row.order_number}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="left">{new Date(row.date).toLocaleDateString()}</TableCell>
                        <TableCell align="center">{row.price ? row.price : 0} руб.</TableCell>
                        <TableCell align="right">
                          {row.payment
                            ? <span className={classes.green}>Оплачено</span>
                            :
                            <div>
                              <span className={classes.red}>Не оплачено </span><br/>
                              <Button
                                className={classes.buttonPay}
                                color="primary"
                                block
                                size="sm"
                                onClick={() => {
                                  setClassicModal(true);
                                  setresourceOrderNumber(row.resourceOrderNumber);
                                }}
                              >
                                <LibraryBooks className={classes.icon}/>
                                Как оплатить (ЕРИП)
                              </Button>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <Button
                  round
                  color="rose"
                  className={classes.navButton}
                  onClick={logout}
                >
                  <ExitToAppIcon className={classes.icons}/>
                  Выйти из профиля
                </Button>
              </GridItem>
            </GridContainer>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={classicModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setClassicModal(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setClassicModal(false)}
                >
                  <Close className={classes.modalClose}/>
                </IconButton>
                <h4 className={classes.modalTitle}>Система «Расчет» (ЕРИП)</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <p>
                  <h4>Номер заказа в системе "Расчет": <b>{resourceOrderNumber}</b></h4>
                  <br/>
                  С помощью Системы «Расчет» (ЕРИП):<br/>
                  • электронными деньгами (iPay, Webmoney (WMB) и др.);<br/>
                  • платежной карточкой (интернет-банк, мобильный-банк, банкомат, инфокиоск, касса банка и Белпочты*);<br/>
                  • наличными (устройство cash-in, касса банка и Белпочты*).<br/>
                  <br/>
                  * если Вы осуществляете платеж в кассе банка или Белпочты, пожалуйста, сообщите кассиру о
                  необходимости проведения оплаты услуги через систему «Расчет» (ЕРИП).
                  <br/><br/>
                  Расположение услуги в дереве ЕРИП:<br/>

                  - Система «Расчет» (ЕРИП)<br/>

                  - Фото, видео, полиграфия, реклама<br/>

                  - Могилевская обл.<br/>

                  - ИП Сотниченко Ю.А.<br/>

                  ► Фото-видео услуги<br/><br/>

                  Нахождение услуги в дереве услуг системы «Расчет» (ЕРИП) в некоторых пунктах оплаты может отличаться
                  от описанного выше. В связи с этим, в случае возникновения проблем с поиском услуги, предлагаем
                  выполнить поиск по УНП: 791233325.
                </p>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => setClassicModal(false)}
                  color="primary"
                  size="sm"
                >
                  Закрыть
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modalPhone
              }}
              open={phoneModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setPhoneModal(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setPhoneModal(false)}
                >
                  <Close className={classes.modalClose}/>
                </IconButton>
                <h4 className={classes.modalTitle}>Добавление телефона</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <p>Пожалуйста, введите номер телефона в <br/>международном формате <br/><b>+375 ** *** ** **</b></p>
                <CustomInput
                  labelText="Телефон"
                  id="phone"
                  formControlProps={{
                    fullWidth: true,
                    onChange: handleChange
                  }}
                  inputProps={{
                    type: "tell",
                    ref: inputRef,
                    placeholder: "+375 ** *** ** **",
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneIcon className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  disabled={btnDisable}
                  onClick={() => {
                    addPhone(inputRef.current.children[0].value, auth._id);
                    setPhoneModal(false);
                    setPhone(inputRef.current.children[0].value);
                    history.push('/profile');
                  }}
                  color="primary"
                  size="sm"
                >
                  Применить
                </Button>
                <Button
                  onClick={() => setPhoneModal(false)}
                  color="primary"
                  size="sm"
                >
                  Отмена
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  orders: PropTypes.array,
  addPhone: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  auth: state.Auth,
  orders: state.Orders.orders,
});


export default connect(
  mapStateToProps,
  {logout, getOrders: userOrders, addPhone}
)(ProfilePage)
