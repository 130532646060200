import {container, title} from "assets/jss/material-kit-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.js";


const canvasStyle = {
  container: {
    zIndex: "12",
    position: "relative",
    ...container
  },
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "10px",
    marginTop: "0",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "left"
  },
  description: {
    color: "#999",
    textAlign: "left"
  },
  textCenter: {
    textAlign: "left"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },

  price: {
    color: '#E91E63',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'italic'
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  },
  checkedIcon: {
    color: "red"
  },
  buttonAddImg: {
    position: "relative"
  },
  inputAddImg: {
    cursor: "pointer",
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
    width: "100%",
    opacity: "0",
  },

  canvasBoxImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  layout: {
    height: '700px',
    background: '#b1b1b1',
    overflow: 'hidden!important',
    position: 'relative'
  },
  layoutItem: {
    background: '#21a226'
  },
  error: {
    color: '#e91e62',
    fontWeight: "500",
    fontStyle: "italic",
  },
  ...tooltipsStyle,
  ...customCheckboxRadioSwitch
};

export default canvasStyle;
