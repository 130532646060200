import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
const useStyles = makeStyles(styles);

const SelectStatus = ({value,onChange}) => {
    const classes = useStyles();

    const [status, setStatus] = React.useState("1");

    const handleChange = event => {
        setStatus(event.target.value);
        onChange(event.target.value);
    };

    useEffect(() => {
        setStatus(value);
    }, [value]);

    return (
        <FormControl>
            <Select
                className={classes.select}
                value={status}
                onChange={(e)=>{handleChange(e)}}
            >
                <MenuItem value='Оформлен'>Оформлен</MenuItem>
                <MenuItem value='В работе'>В работе</MenuItem>
                <MenuItem value='Выполнен'>Выполнен</MenuItem>
                <MenuItem value='Отменен'>Отменен</MenuItem>
            </Select>
        </FormControl>
    );
};


SelectStatus.defaultProps = {
    value:'',
    onChange:()=>{}
};

SelectStatus.propTypes = {
  value:PropTypes.string,
  onChange:PropTypes.func
};

export default SelectStatus;
