import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { loadUser } from "./actions/auth";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ConstructorPage from "views/ConstructorPage/ConstructorPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import ComingSoonPage from "views/ComingSoonPage/ComingSoonPage.js";
import RegistrationPage from "views/RegistrationPage/RegistrationPage.js";
import HomePage from "views/HomePage/HomePage.js";
import CanvasPage from "views/CanvasPage/CanvasPage.js";
import YourPage from "views/YourPage/YourPage.js";
import AdministratorPage from "views/Administrator/AdministratorPage.js";
import PolitikaPage from "views/PolitikaPage/PolitikaPage.js";
import HighSchoolPage from "views/school/HighSchoolPage.js";
import JoniorSchoolPage from "views/school/JoniorSchoolPage.js";
import KindergartenPage from "views/school/KindergartenPage.js";
import PhotographerPage from "views/services/PhotographerPage.js";
import DesignerPage from "views/services/DesignerPage.js";
import MagnetPage from "views/magnet/MagnetPage.js";
import cupPage from "views/cupPage/cupPage.js";

//redux
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from "./utils/setAuthToken";

let hist = createBrowserHistory();

const App = () => {
    
    if (localStorage.token){
        setAuthToken(localStorage.token)
    }
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

return(
    <Provider store={store}>
        <Router history={hist}>
            <Switch>
                <Route path="/landing" component={LandingPage} />
                <Route path="/constructor" component={ConstructorPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/canvas" component={CanvasPage} />
                <Route path="/coming-soon" component={ComingSoonPage} />
                <Route path="/registration" component={RegistrationPage} />
                <Route path="/profile" component={YourPage} />
                <Route path="/administrator" component={AdministratorPage} />
                <Route path="/politika" component={PolitikaPage} />
                <Route path="/high-school" component={HighSchoolPage} />
                <Route path="/jonior-school" component={JoniorSchoolPage} />
                <Route path="/kindergarten" component={KindergartenPage} />
                <Route path="/photographer" component={PhotographerPage} />
                <Route path="/designer" component={DesignerPage} />
                <Route path="/magnet" component={MagnetPage} />
                <Route path="/cup" component={cupPage} />
                <Route path="/" component={HomePage} />
            </Switch>
        </Router>
    </Provider>
)};

export default App;
