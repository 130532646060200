import React, {useState} from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SectionConstructor from "./constructor/Constructor.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import photo from "assets/img/27884_large.jpg";
import {ALBUMS} from "./albumMap.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

const ConstructorPage = ({isAuthenticated, auth, sendOrder, postImage, history}) => {
  const classes = useStyles();
  const sizeMap = [
    {type:"small",width: 725, height: 500, aspect: 297 / 210, price:13, label: "14х20"},//original size * 2.5
    {type:"big",width: 800, height: 580, aspect: 420 / 297, price:23, label: "20х29"},//original size * 2
  ];

  const [sizePhoto, setSizePhoto] = useState("small");

  const templateMap = ALBUMS;

  const [templatePhoto, setTemplatePhoto] = useState("0");

  const pageMap = [
    {type:"3", label: "3 разворота", page: 3, price: 0},
    {type:"5", label: "5 разворотов", page: 5, price: 2},
    // {type:"6", label: "6 разворотов", page: 6},
    {type:"7", label: "7 разворотов", page: 7, price: 4},
  ];

  const [pagePhoto, setPagePhoto] = useState(null);

  let summPhoto = pagePhoto ? Math.ceil(sizeMap.find(size => size.type === sizePhoto).price + (1 * pageMap.find(size => size.type === pagePhoto).price)) : 0;
  
  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax
        small
        filter
        image={
          "https://w-dog.ru/wallpapers/12/19/436089275281828/zhivopis-kartina-xolst-kraski-yarko-abstrakciya.jpg"
        }
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <GridContainer justify="space-between">
            <GridItem cs={12} sm={12} md={6}>
              <img
                alt="фотоальбом"
                src={photo}
                className={classes.img}
              />
            </GridItem>
            <GridItem cs={12} sm={12} md={6}>
              <h2 className={classes.title}>Печать фотоальбома</h2>
              <div className={classes.price}>
                {summPhoto} руб</div>
              <h3 className={classes.description}>Описание</h3>
              <p className={classes.description}>
                Индивидуальный подход к созданию.
                Моменты навсегда останутся с Вами.
                Ламинирование,выполняющее защитную и декоративную функции.
                Все фотокниги в твердой обложке, дополнительную информацию узнавайте по телефону.<br/>
                Размеры фотоальбома: Формат - A4, Формат - A5.<br/>
              </p>
              <form>
                <GridContainer>
                  <GridItem>
                    <h4>Выберите размер</h4>
                  </GridItem>
                  <GridItem >
                    {
                      sizeMap.map((size, idx)=>{
                        return (<FormControlLabel
                          control={
                            <Radio
                              checked={sizePhoto === size.type}
                              onChange={() => setSizePhoto(size.type)}
                              value={size.aspect}
                              name="size"
                              icon={
                                <Check className={classes.uncheckedIcon} />
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label={size.label}
                          key={idx}
                        />)
                      })
                    }
                  </GridItem>
                  <GridItem>
                    <h4>Тематика шаблона</h4>
                  </GridItem>
                  <GridItem >
                    {
                      templateMap.map((size, idx)=>{
                        return (<FormControlLabel
                          control={
                            <Radio
                              checked={templatePhoto === size.type}
                              onChange={() => setTemplatePhoto(size.type)}
                              value={size.aspect}
                              name="size"
                              icon={
                                <Check className={classes.uncheckedIcon} />
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label={size.label}
                          key={idx}
                        />)
                      })
                    }
                  </GridItem>
                  <GridItem>
                    <h4>Количество разворотов</h4>
                  </GridItem>
                  <GridItem >
                    {
                      pageMap.map((size, idx)=>{
                        return (
                          <Tooltip
                            id="tooltip-right"
                            title={ isAuthenticated ? (auth.phone ? "" : "Перед созданием альбома необходимо добавить номер телефона в профиле") : "Перед созданием альбома пожалуйста войдите в профиль"}
                            placement="top"
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={pagePhoto === size.type}
                                  onChange={() =>
                                  {
                                    if (isAuthenticated && auth.phone)
                                      setPagePhoto(size.type)
                                  }
                                  }
                                  value={size.aspect}
                                  name="size"
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                                root: classes.labelRoot
                              }}
                              label={size.label}
                              key={idx}
                            />
                          </Tooltip>
                        )
                      })
                    }
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.bgGrey}>
          <SectionConstructor
            width={sizeMap.find(size => size.type === sizePhoto).width}
            height={sizeMap.find(size => size.type === sizePhoto).height}
            aspectSize={sizeMap.find(size => size.type === sizePhoto).aspect}
            background={templateMap.find(size => size.type === templatePhoto).background}
            cover={templateMap.find(size => size.type === templatePhoto).cover}
            page={pagePhoto ? pageMap.find(size => size.type === pagePhoto).page : 0}
            schemas={templateMap.find(size => size.type === templatePhoto).schemas}
            size={sizeMap.find(size => size.type === sizePhoto).label}
            price={summPhoto}
            history={history}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

ConstructorPage.propTypes = {
  sendOrder: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  auth: state.Auth
});


export default connect(mapStateToProps,null)(ConstructorPage)
