import React , { useRef } from 'react';
import PropTypes from 'prop-types';

import compose from 'recompose/compose'

import {withStyles} from "@material-ui/core/styles/index";
import '../../../../src/assets/scss/index.scss';
import Slider from "react-slick";
import Button from "components/CustomButtons/Button.js";
import InputIcon from '@material-ui/icons/Input';
import OneStage from "./OneStage.js";
import {connect} from "react-redux";
import {postOrder} from "../../../actions/orders";
import {postImage} from "../../../actions/imageYandex";
import CachedIcon from "@material-ui/icons/Cached";

const styles = {
  constructorSlider: {
    padding: "20px 50px",
  },
  constructorFinish: {
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
  },
  centerImage: {
    width: '100%',
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center'
  },
};

let mapStage = [];

class Constructor extends React.Component {

  static propTypes = {
    aspectSize: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    background: PropTypes.string,
    cover: PropTypes.string,
    page: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
    sendOrder: PropTypes.func.isRequired,
    postImage: PropTypes.func.isRequired,
    size: PropTypes.number,
    price: PropTypes.number,
    history: PropTypes.func.isRequired
  };

  static defaultProps = {
    aspectSize: 297 / 210,
    width: 290,
    height: 200,
    background: 'url(https://cs6.livemaster.ru/storage/5b/98/6ff30c4ad107694d513c473bb6mo.jpg)',
    cover: 'url(https://www.publicdomainpictures.net/pictures/320000/velka/background-image.png)',
    page: 4
  };

  state = {
    stageX: 0,
    stageY: 0,
    stageScale: 1,
    stageWidth: 900,
    stageHeight: 500,
    image: "",
    selectedShapeName: "",
    activeSlide: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      masRef2: [],
      imagesPreloader:[],
      unselect: false
    };
    this.masRef = [];
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }
  
  render() {
    const {classes} = this.props;
    const countPages = this.props.page;
    const schemasPages = this.props.schemas;

    mapStage=[];

    for (let i = 0; i <= countPages; i++){
      if (i<1) {
        mapStage.push({background:this.props.cover, ...schemasPages[i]});
      } else{
        mapStage.push({background:this.props.background, ...schemasPages[i]});
      }
    }
    
    const sendPhoto = () => {
      this.setState({
        unselect: true
      })
      let imageName = ""
      let len = this.masRef.length;
      this.masRef.map(async (item, index) => {
        const date = new Date();
        
        let blob = await fetch(item.current.toDataURL({ pixelRatio: 5 })).then(r => r.blob());

        const fileName = this.props.auth.email+'-'
            + date.getDate()+ '-' +
            + date.getMonth()+ '-' +
            + date.getFullYear()
            + '-' + date.getHours() + ':' +
            + date.getMinutes() + '-' + index
            +'.png';
        this.props.postImage(blob, 'Фотокниги', fileName)
            .then(() => {
              len--;
              imageName += (fileName+'\n')
              if (len === 0 ) {
                this.props.sendOrder(this.props.auth._id, "Фотоальбом", this.props.size, "1", imageName, "Оформлен", this.props.price, this.props.auth.phone)
                    .then(()=>{
                        this.props.history.push('/profile');
                    })
                    .catch((err)=>{
                      console.log(err);
                    });
              }

            })
            .catch((err)=>{
              console.log(err);
            });
      });
      let imagesPreloader=[];
      imagesPreloader = this.masRef.map(async (item, index) => {await fetch(item.current.toDataURL({ pixelRatio: 5 })).then(r => r.url);});
      this.setState({imagesPreloader});
      // console.log(this.state)
    };
    
    let imagesPreloader = [];
    this.state.masRef2.map(async (item) => {
        let path =  "";
        path =  item.current? item.current.toDataURL() : "";
        if (path !== "" ) {
          imagesPreloader.push(path);
        }
    });
    
    return (
      <div className={classes.constructorSlider} >
        {

        }
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          draggable={false}
          infinite={false}
          beforeChange={(current, next) => this.setState({ activeSlide: next })}
        >
          {mapStage.length > 1 && mapStage.map((stage, idx)=>
            <OneStage
              key={idx}
              width={this.props.width}
              height={this.props.height}
              background={stage.background}
              type={stage.type}
              schema={stage.schema}
              text={stage.text}
              textPosition={stage.position}
              activeSlide={this.state.activeSlide}
              tRef={(tRef) => {
                this.masRef[idx] = tRef;
                let newRef = this.state.masRef2;
                newRef.push(tRef);
                this.setState({
                  masRef2: newRef
                });
              }}
              unselect={this.state.unselect}
            />
          )}
        </Slider>
        
        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={countPages+1}
          swipeToSlide={false}
          focusOnSelect={false}
          infinite={false}
        >
          {mapStage.length > 1 && mapStage.map((stage, idx) => 
            <div className="PreviewItem" key={idx}>
              <p>{ idx == 0 ? "Обложка" : "Разворот"+' '+idx }</p>
              <div>
              <OneStage
                class="onlyPreview"
                width={100}
                height={75}
                background={stage.background}
                type={stage.type}
                schema={stage.schema}
                tRef={(tRef) => {}}
              />
              </div>
            </div>
          )}

        </Slider>
        <div className={classes.constructorFinish}>
          {mapStage.length > 1 && <Button disabled={this.state.unselect} color="rose" size="lg" endIcon={<InputIcon />} onClick={
            (e)=> {
              this.setState({activeSlide:100, unselect: true});
              setTimeout(sendPhoto, 10);
            }
          }>{this.state.unselect && <i className="fa-spin"><CachedIcon style={{margin: "0"}} /></i> }
            Заказать фотоальбом
          </Button>}
        </div>
       {/*Preview для теста генерации картинок*/}
        {/*{imagesPreloader.length>1&&imagesPreloader.map((imageUrl, idx)=> <img src={imageUrl} alt="" width={100} height={75} key={idx}/>)}*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  sendOrder: PropTypes.func.isRequired,
  auth: state.Auth
});

export default  compose(
    withStyles(styles),
    connect(mapStateToProps,{sendOrder:postOrder, postImage:postImage })
)(Constructor);
