import React, {useState} from "react";

import { postOrder } from "../../../actions/orders"

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";


import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/canvasStyle.js";
import {connect} from "react-redux";
import PropTypes from "prop-types";


import SectionCropper from "./SectionCropper";
import { postImage } from "../../../actions/imageYandex";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const useStyles = makeStyles(styles);

const WorkSection = ({ isAuthenticated, auth, sendOrder, history, postImage }) => {
  const classes = useStyles();
  
  const sizeMap = [
    {type:"small", aspect: 170 / 260, price:16, label: "17х26см (вертикальное расположение)"},
    {type:"big", aspect: 260 / 380, price:23, label: "26х38см (вертикальное расположение)"},
    {type:"smallHor", aspect: 260 / 170, price:16, label: "26x17см (горизонтальное расположение)"},
    {type:"bigHor", aspect: 380 / 260, price:23, label: "38х26см (горизонтальное расположение)"}
  ];
  
  const [sizeCanvas, setSizeCanvas] = useState("big");
  const [imageFile, setImageFile] = useState(null);
  const [imageCanvas, setImageCanvas] = useState(null);
  const [errorCanvas, setErrorCanvas] = useState(null);
  const [phoneModal, setPhoneModal] = useState(false);


  const onSubmit = async e => {
    e.preventDefault();
    if (imageFile){
      setErrorCanvas(null);
      const date = new Date();

      let blob = await fetch(imageFile).then(r => r.blob());
      const fileName = date.getDate()+ '-' +
          + date.getMonth()+ '-' +
          + date.getFullYear()
          + '-' + date.getHours() + ':' +
          + date.getMinutes() + ':' +
          + date.getSeconds()
          +'.jpg';
      postImage(blob, 'Холсты', fileName)
          .catch((err)=>{
            console.log(err);
            setErrorCanvas("Произошла ошибка при загрузке(");
          });
      const size = sizeMap.find(size => size.type === sizeCanvas).label;
      const price = sizeMap.find(size => size.type === sizeCanvas).price;
      sendOrder(auth._id, "Холст", size, "1", 'disk:/холст/'+fileName, "Оформлен", price, auth.phone)
        .then(()=>{
          history.push('/profile');
        })
        .catch((err)=>{
          console.log(err);
          setErrorCanvas("Что-то пошло не так(");
        });
    } else{
      setErrorCanvas("Загрузите изображение");
    }
  };
  
  const setImage = (croppedImage) => {
    setImageFile(croppedImage);
  };
  
  return (
    <div className={classes.container}>
    <div className={classes.section}>
      <GridContainer justify="space-between">
        <GridItem cs={12} sm={12} md={6}>
          <SectionCropper 
              aspectSize={sizeMap.find(size => size.type === sizeCanvas).aspect}
              croppedImageFinish={setImage}
          />
        </GridItem>
        <GridItem cs={12} sm={12} md={6}>
          <h2 className={classes.title}>Печать холста</h2>
          <div className={classes.price}>
            {sizeMap.find(size => size.type === sizeCanvas).price} руб</div>
          <h3 className={classes.description}>Описание</h3>
          <p className={classes.description}>
            Натуральный хлопковый холст<br/>
            Галерейная натяжка с креплением для стены<br/>
            Лаковое покрытие,выполняющее защитную и декоративную функции<br/>
            Форматы: {sizeMap[0].label}, {sizeMap[1].label} - вертикальный; {sizeMap[2].label}, {sizeMap[3].label} - горизонтальный.<br/>
          </p>
          <form>
            <GridContainer>
              <GridItem>
               <h4>Выберите размер</h4>
              </GridItem>
              <GridItem >
                {
                  sizeMap.map((size)=>{
                    return (<FormControlLabel
                      control={
                        <Radio
                          checked={sizeCanvas === size.type}
                          onChange={() => setSizeCanvas(size.type)}
                          value={size.aspect}
                          name="size"
                          icon={
                            <Check className={classes.uncheckedIcon} />
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label={size.label}
                    />)
                  })
                }
              </GridItem>
              <br/>
              <GridItem>
                <br/><br/>
                  <Tooltip
                    id="tooltip-left"
                    title={ isAuthenticated ? "" : "Перед созданием холста пожалуйста войдите в профиль"}
                    placement="bottom"
                  >
                      <Button 
                        color={ isAuthenticated && auth.phone ? "rose" : ""}
                        size="lg"
                        onClick={(e)=> {
                          if((isAuthenticated && auth.phone)) {
                            onSubmit(e);
                          } else setPhoneModal(true);
                          console.log()
                        }}
                      >Заказать печать</Button>
                  </Tooltip>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modalPhone
                  }}
                  open={phoneModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setPhoneModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  {/*<DialogTitle*/}
                  {/*  id="classic-modal-slide-title"*/}
                  {/*  disableTypography*/}
                  {/*  className={classes.modalHeader}*/}
                  {/*>*/}
                  {/*  <IconButton*/}
                  {/*    className={classes.modalCloseButton}*/}
                  {/*    key="close"*/}
                  {/*    aria-label="Close"*/}
                  {/*    color="inherit"*/}
                  {/*    onClick={() => setPhoneModal(false)}*/}
                  {/*  >*/}
                  {/*    <Close className={classes.modalClose}/>*/}
                  {/*  </IconButton>*/}
                  {/*  /!*<h4 className={classes.modalTitle}>Добавление телефона</h4>*!/*/}
                  {/*</DialogTitle>*/}
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>{isAuthenticated ? (auth.phone ? "" : "Перед созданием холста необходимо добавить номер телефона в профиле") : "Перед созданием холста пожалуйста войдите в профиль"}</p>

                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      onClick={() => {
                        if (!isAuthenticated)
                        history.push('/login');
                        else
                        history.push('/profile');
                      }}
                      color="primary"
                      size="sm"
                    >
                      { isAuthenticated ? "Перейти" : "Войти"}  в профиль
                    </Button>
                    <Button
                      onClick={() => setPhoneModal(false)}
                      color="primary"
                      size="sm"
                    >
                      Отмена
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
              <GridItem>
                <h4 className={classes.error}>{errorCanvas}</h4>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>,
      </GridContainer>
    </div>
    </div>
  );
};

WorkSection.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  sendOrder: PropTypes.func.isRequired,
  postImage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  auth: state.Auth
});


export default connect(mapStateToProps,{sendOrder:postOrder, postImage:postImage })(WorkSection)


