import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "@material-ui/icons/Dashboard";

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PanoramaIcon from '@material-ui/icons/Panorama';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import wedding1 from "assets/img/wedding/1.jpg";
import wedding2 from "assets/img/wedding/2.jpg";
import wedding3 from "assets/img/wedding/3.jpg";
import wedding4 from "assets/img/wedding/4.jpg";

import canvas1 from "assets/img/canvas/1.jpg";
import canvas2 from "assets/img/canvas/2.jpg";
import canvas3 from "assets/img/canvas/3.jpg";
import canvas4 from "assets/img/canvas/4.jpg";
import canvas5 from "assets/img/canvas/5.jpg";


import photo1 from "assets/img/photo/1.jpg";
import photo2 from "assets/img/photo/2.jpg";
import photo3 from "assets/img/photo/3.jpg";
import photo4 from "assets/img/photo/4.jpg";
import photo0 from "assets/img/photo/0.jpg";



import styles from "assets/jss/material-kit-react/views/sectionOurWorkPage.js";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <h2 className={classes.title}>НАШИ РАБОТЫ</h2>
          {/*<h5 className={classes.description}>*/}
            {/*Смысл жизни, следовательно, творит данный закон внешнего мира. Согласно мнению известных философов, дедуктивный метод естественно порождает и обеспечивает мир, tertium nоn datur.*/}
            {/*Надстройка нетривиальна. Дискретность амбивалентно транспонирует гравитационный парадокс.*/}
          {/*</h5>*/}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                color="rose"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 2, md: 2 },
                  contentGrid: { xs: 12, sm: 10, md: 10 }
                }}
                tabs={[
                  {
                    tabButton: "Свадебные фото",
                    tabIcon: PhotoLibraryIcon,
                    tabContent: (
                        <div className={classes.divContainer}>
                            <h4  className={classes.centerText}>В своей работе мы используем индивидуальный подход.</h4>
                            <h3  className={classes.centerPrice}> Цены от 400руб</h3>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                              <img
                                alt="..."
                                src={wedding1}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={wedding3}
                                className={navImageClasses}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <img
                                alt="..."
                                src={wedding2}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={wedding4}
                                className={navImageClasses}
                              />
                            </GridItem>
                          </GridContainer>
                        </div>
                    )
                  },
                  {
                    tabButton: "Фотоальбомы",
                    tabIcon: Dashboard,
                    tabContent: (
                      <div className={classes.divContainer}>
                        <h4  className={classes.centerText}>Ламинация всех листов фотоальбома(защита от влажности,загрязнений и солнечного
                          света.)</h4>
                        <h3  className={classes.centerPrice}> Цены от 13руб</h3>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={photo4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={photo2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={photo3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={photo1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={photo0}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    )
                  },
                  {
                    tabButton: "Холсты",
                    tabIcon: PanoramaIcon,
                    tabContent: (
                      <div className={classes.divContainer}>
                        <h4  className={classes.centerText}>Лаковое покрытие,выполняющее защитную и декоративную функции</h4>
                        <h3  className={classes.centerPrice}> Цены от 16руб</h3>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={canvas2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={canvas1}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={canvas3}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={canvas4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={canvas5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
