import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import {getOrientation} from 'get-orientation/browser'
import getCroppedImg from './cropImage'
import {getRotatedImage} from './rotateImage'
//import './styles.css'
import Button from "components/CustomButtons/Button.js";
import Input from '@material-ui/core/Input';
import ImgDialog from './ImgDialog'


import {withStyles} from "@material-ui/core/styles/index";

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

const styles = {
  inputStyle: {
    cursor: "pointer",
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
    width: "100%",
    opacity: "0",
  },
  canvasBox: {
    background: "rgba(215, 215, 215 ,0.35)",
    height: "500px",
    width: "100%",
    border: "1px solid #999",
    marginBottom: "40px",
    position: "relative"
  },
  cropContainer: {
    width: "100%",
    height: "500px",
    position: "relative",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  }
};

export class SectionCropper extends React.Component {
  static propTypes = {
    aspectSize: PropTypes.number
  };

  static defaultProps = {
    aspectSize: 260 / 380
  };

  state = {
    imageSrc: null,
    crop: {
      x: 0,
      y: 0
    },
    zoom: 1,
    croppedAreaPixels: null,
    croppedImage: null,
    isCropping: false,
  };

  onCropChange = crop => {
    this.setState({crop});
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    })
  };

  onZoomChange = zoom => {
    this.setState({zoom});
  };

  showResult = async () => {
    try {
      this.setState({
        isCropping: true,
      });
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels
      );
      this.setState({
        croppedImage,
        isCropping: false,
      })
    } catch (e) {
      console.error(e);
      this.setState({
        isCropping: false,
      })
    }
  };

  onClose = async () => {
    this.setState({
      croppedImage: null,
    })
  };

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      this.setState({
        imageSrc: imageDataUrl,
        crop: {x: 0, y: 0},
        zoom: 1,
      })
    }
  };

  render() {
    const {classes} = this.props;
    return (
      <div>

        {this.state.imageSrc ? (
          <Fragment>
            <div className={classes.cropContainer}>
              <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                guides={false}
                zoom={this.state.zoom}
                aspect={this.props.aspectSize}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
            <div className={classes.controls}>
              <Slider
                value={this.state.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => this.onZoomChange(zoom)}
                color='primary'
              />
            </div>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={this.showResult}
                disabled={this.state.isCropping}
              >
                Предварительный просмотр
              </Button>
            </div>
            <ImgDialog img={this.state.croppedImage} onClose={this.onClose}/>
          </Fragment>

        ) : (<div className={classes.canvasBox}></div>)}

        <Button color="primary" round size="lg">
          <span>{this.state.imageSrc ? 'Изменить' : 'Добавить'} изображение</span>
          <Input
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            onChange={this.onFileChange}
            className={classes.inputStyle}
          />
        </Button>
      </div>
    )
  }
}

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file)
  })
}


export default withStyles(styles)(SectionCropper);
