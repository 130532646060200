import React, { useState, createRef } from 'react';
import { Stage, Layer, Image, Text} from 'react-konva';
import useImage from 'use-image';

export default function Schemas ( props ) {
  const URL = './add2.png';
  const elementCoverLoveStory = './albums/albums-elements/element-cover-love-story.png';
  const elementCoverWedding = './albums/albums-elements/imgElementsWedding.png';
  const elementCoverChildren = './albums/albums-elements/children-element.png';
  const elementCoverTraveling = './albums/albums-elements/imgElementsTraveling.png';
  const elementCoverHappy = './albums/albums-elements/imgElementsHappy.png';
  const [image] = useImage(URL);
  const [imgElementCoverLoveStory] = useImage(elementCoverLoveStory);
  const [imgElementsWedding] = useImage(elementCoverWedding);
  const [imgElementsChildren] = useImage(elementCoverChildren);
  const [imgElementsTraveling] = useImage(elementCoverTraveling);
  const [imgElementsHappy] = useImage(elementCoverHappy);

  const {schema="", width=1, height=1, onClick=()=>{}, text="",text2=""} = props;
  // const padding = 0.02 * width;
  const padding = 0.02 * width / 2;

  switch(schema) {
    case 'zero':
      return (<></> );
    case 'schema1':
      return (<Image width={width-padding*2} height={height-padding*2} image={image} x={padding} y={padding} opacity={0.75}
                     onClick={(e) => {onClick(e)}}
                     draggable/>
      );
    case 'schema2':
      return (<>
        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema2-double':
      return (<>
        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema3':
      return (<>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema3-vertical':
      return (<>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema4':
      return (<>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema4-variable':
      return (<>
        <Image width={width/2-padding*2} height={height*0.75-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height/4-padding*2} image={image} x={padding} y={height*0.75+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height/4-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height*0.75-padding*2} image={image} x={width/2+padding} y={height/4+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema4-vertical':
      return (<>
        <Image width={width/4-padding*2} height={height-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height-padding*2} image={image} x={width/4+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/4-padding*2} height={height-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height-padding*2} image={image} x={width*0.75+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema5':
      return (<>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/4+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/4+padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/2-padding*2} height={height-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema6':
      return (<>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/4+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width*0.75+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'schema8':
      return (<>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/4+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/4+padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>

        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width*0.75+padding} y={padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Image width={width/4-padding*2} height={height/2-padding*2} image={image} x={width*0.75+padding} y={height/2+padding} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
      </>);
    case 'cover-love-story':
      return (<>
          <Image width={width/2} height={height} image={image} x={width/2} y={0} draggable opacity={0.75}
                 onClick={(e) => {onClick(e)}}/>
          {/*<Image width={width/2} height={height/2.5} image={imgElementCoverLoveStory} x={width/2} y={height-height/2.5} opacity={1}*/}
                 {/*draggable*/}
                 {/*onClick={(e) => {e.target.moveToTop();}}*/}
                 {/*onDragStart={(e) => {e.target.moveToTop();}}*/}
          {/*/>*/}
          {/*<Text text={"Test cover"} x={100} y={100} fontSize={40} fill={'red'}/>*/}
        </>
      );
    case 'cover-wedding':
      return (<>
          <Image width={width/2} height={height} image={image} x={0} y={0} opacity={0.75}
                 onClick={(e) => {onClick(e)}}/>
          <Image width={width/4-padding*2} height={height/2} image={image} x={width/2+padding} y={height/4} opacity={0.75}
                 onClick={(e) => {onClick(e)}}/>
          <Image width={width/4-padding*2} height={height/2} image={image} x={width*0.75+padding} y={height/4} opacity={0.75}
                 onClick={(e) => {onClick(e)}}/>
          {/*<Image width={width/3} height={height/3.7} image={imgElementsWedding} x={width/1.75} y={height/1.5} opacity={1}*/}
                 {/*draggable*/}
                 {/*onClick={(e) => {e.target.moveToTop();}}*/}
                 {/*onDragStart={(e) => {e.target.moveToTop();}}*/}
          {/*/>*/}
        </>
      );
    case 'cover-family':
      return (<>
          <Image width={width/2} height={height} image={image} x={width/2} y={0} opacity={0.75}
                 onClick={(e) => {onClick(e)}}/>
        </>
      );
    case 'cover-children':
      return (<>
          <Image width={width/2 - padding*2} height={height/1.75} image={image} x={width/2 + padding} y={padding} opacity={0.75}
                 onClick={(e) => {onClick(e)}}/>
        </>
      );
    case 'cover-children1':
      return (<>
          {/*<Image width={width/2-padding*4} height={height/2-padding*2} image={imgElementsChildren} x={padding*2} y={padding*10} opacity={1}*/}
                 {/*draggable*/}
                 {/*onClick={(e) => {e.target.moveToTop();}}*/}
                 {/*onDragStart={(e) => {e.target.moveToTop();}}*/}
          {/*/>*/}
          <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={padding} opacity={0.75}
                 onClick={(e) => {onClick(e)}}
                 draggable/>
          <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={height/2+padding} opacity={0.75}
                 onClick={(e) => {onClick(e)}}
                 draggable/>
        </>
      );
    case 'cover-traveling':
      return (<>

          <Image width={width/4} height={height/2.5} image={image} x={width/2} y={0} opacity={0.75}
                 onClick={(e) => {onClick(e)}}
                 draggable/>
          <Image width={width/4} height={height/2.5} image={image} x={width*0.75} y={0} opacity={0.75}
                 onClick={(e) => {onClick(e)}}
                 draggable/>
          <Image width={width/4} height={height - height/2.5} image={image} x={width/2} y={height/2.5} opacity={0.75}
                 onClick={(e) => {onClick(e)}}
                 draggable/>
          {/*<Image width={width/2} height={height/2.5} image={imgElementsTraveling} x={width/2} y={height-height/2.5} opacity={1}*/}
                 {/*onClick={(e) => {e.target.moveToTop();}}*/}
                 {/*onDragStart={(e) => {e.target.moveToTop();}}*/}
          {/*/>*/}
        </>
      );
    case 'cover-portfolio':
      return (<>
        </>
      );
    case 'cover-happy':
      return (<>
          <Image width={width/2.5} height={height/3} image={imgElementsHappy} x={width/1.75} y={height/3} opacity={1}
                 draggable
                 onClick={(e) => {e.target.moveToTop();}}
                 onDragStart={(e) => {e.target.moveToTop();}}
          />
        </>
      );
    default: return (<>
        <Image width={width/2-padding*2} height={height/2-padding*2} image={image} x={width/2+padding} y={height/4} opacity={0.75}
               onClick={(e) => {onClick(e)}}
               draggable/>
        <Text text={schema}/>
      </>
    )
  }
}

