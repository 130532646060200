import React, { useState }from "react";
import { Link} from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { setAlert } from '../../actions/alert'
import { register } from "../../actions/auth";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import PhoneIcon from '@material-ui/icons/Phone';
import Check from "@material-ui/icons/Check";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Footer from "../../components/Footer/Footer";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import CustomInput from '../../components/CustomInput/CustomInput';
import Alert from "../../components/Snackbar/Alert";

import styles from "../../assets/jss/material-kit-react/views/loginPage";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

const RegistrationPage = (props) => {
    
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
        setTimeout(function() {
        setCardAnimation("");
        }, 700);

    const classes = useStyles();
    const { ...rest } = props;
    if (props.isAuthenticated){
      props.history.push('/');
    }
    return (
        <div>
          <Header
            absolute
            color="transparent"
            brand="A-PRIORI"
            rightLinks={<HeaderLinks />}
            {...rest}
          />
          <div
            className={classes.pageHeader}
            style={{
              backgroundImage: "url(" + image + ")",
              backgroundSize: "cover",
              backgroundPosition: "top center"
            }}
          >
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                  <Card className={classes[cardAnimaton]}>
                    <Formik
                      initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        password: '',
                        password2: '',
                        acceptTerms: false
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string()
                          .required('Введите Имя'),
                        email: Yup.string()
                          .email('Введите правильный email')
                          .required('Введите email'),
                        phone: Yup.number()
                          .required('Введите правильный номер телефона'),
                        password: Yup.string()
                          .min(6, 'Минимум 6 символов')
                          .required('Введите правильный номер пароль'),
                        password2: Yup.string()
                          .oneOf([Yup.ref('password'), null], 'Повторите правильный пароль')
                          .required('Повторите пароль'),
                        acceptTerms: Yup.bool().oneOf([true], 'Ознакомтесь с политикой конфиденциальности')
                      })}
                      onSubmit={fields => {

                        props.register({
                          name: fields.name,
                          email: fields.email.toLocaleLowerCase(),
                          phone: fields.phone,
                          password: fields.password,
                        });
                      }}
                      render={({ errors, status, touched, values, handleChange}) => (
                        <Form className={classes.form}>
                          <CardHeader color="primary" className={classes.cardHeader}>
                            <h4 className={classes.h4}>РЕГИСТРАЦИЯ</h4>
                          </CardHeader>
                          <CardBody>
                            <div className={classes.boxInput}>
                              <CustomInput
                                labelText="Введите Имя"
                                id="name"
                                formControlProps={{
                                  fullWidth: true,
                                  onChange: handleChange,
                                  error: errors.name && touched.name
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "name",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <People className={classes.inputIconsColor} />
                                    </InputAdornment>
                                  )
                                }}
                                required
                              />
                              <p className={classes.boxInputError}>{errors.name && touched.name ? errors.name:""}</p>
                            </div>
                            <div className={classes.boxInput}>
                              <CustomInput
                                labelText="Email..."
                                id="email"
                                formControlProps={{
                                  fullWidth: true,
                                  onChange: handleChange,
                                  error: errors.email && touched.email
                                }}
                                inputProps={{
                                  type: "email",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Email className={classes.inputIconsColor} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                              <p className={classes.boxInputError}>{errors.email && touched.email ? errors.email:""}</p>
                            </div>
                            <div className={classes.boxInput}>
                              <CustomInput
                                labelText="Телефон"
                                id="phone"
                                formControlProps={{
                                  fullWidth: true,
                                  onChange: handleChange,
                                  error: errors.phone && touched.phone
                                }}
                                inputProps={{
                                  type: "tell",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <PhoneIcon className={classes.inputIconsColor} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                              <p className={classes.boxInputError}>{errors.phone && touched.phone?errors.phone:''}</p>
                            </div>
                            <div className={classes.boxInput}>
                              <CustomInput
                                labelText="Пароль"
                                id="password"
                                formControlProps={{
                                  fullWidth: true,
                                  onChange: handleChange,
                                  error: errors.password && touched.password
                                }}
                                inputProps={{
                                  type: "password",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  autoComplete: "off"
                                }}
                              />
                              <p className={classes.boxInputError}>{errors.password && touched.password?errors.password:''}</p>
                            </div>
                            <div className={classes.boxInput}>
                              <CustomInput
                                labelText="Повторите пароль "
                                id="password2"
                                formControlProps={{
                                  fullWidth: true,
                                  onChange: handleChange,
                                  error: errors.password2 && touched.password2
                                }}
                                inputProps={{
                                  type: "password",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  autoComplete: "off"
                                }}
                              />
                              <p className={classes.boxInputError}>{errors.password2 && touched.password2?errors.password2:''}</p>
                            </div>
                            <div className={classes.boxInput}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="acceptTerms"
                                    type="checkbox"
                                    id="acceptTerms"
                                    checked={values.acceptTerms ? values.acceptTerms : false}
                                    tabIndex={-1}
                                    onChange={handleChange}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                  />
                                }
                                classes={{ label: classes.labelPolitika, root: classes.labelRoot }}
                                label="Я ознакомлен с правилами политики конфиденциальности "
                              />
                              <br/>
                              <br/>
                              <p className={classes.boxInputError}>{errors.acceptTerms && touched.acceptTerms?errors.acceptTerms:''}</p>
                            </div>
                            
                          </CardBody>
                          <Alert/>
                          <CardFooter className={classes.cardFooter}>
                            <GridContainer  justify="center">
                              <GridItem xs={12} sm={12} md={12}>
                                <Button
                                  color="rose"
                                  type="submit"
                                >
                                  ЗАРЕГИСТРИВОВАТЬСЯ
                                </Button>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <Link to={"/login"} className={classes.link}>
                                  <Button simple color="primary" size="sm">
                                    У меня есть аккаунт
                                  </Button>
                                </Link>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <Link to={"/politika"} className={classes.link}>
                                  <Button simple color="primary" size="sm">
                                    Политика конфиденциальности
                                   
                                  </Button>
                                </Link>
                              </GridItem>
                            </GridContainer>
                          </CardFooter>
                        </Form>
                      )}
                    />
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
            <Footer whiteFont />
          </div>
        </div>
    );
};

RegistrationPage.propTypes ={
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated
});

export default connect(
    mapStateToProps,
    { setAlert, register }
    )(RegistrationPage);
