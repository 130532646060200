import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import ImageGallery from 'react-image-gallery';
// import './image-gallery.scss';
import imageBG from "assets/img/photo/bg4.jpg";
import logo from "assets/img/logo.png";

import image1 from "assets/img/photographer/photo_2021-01-31_11-56-39.jpg";
import image2 from "assets/img/photographer/photo_2021-01-31_11-56-54.jpg";
import image3 from "assets/img/photographer/photo_2021-01-31_11-57-01.jpg";
import image4 from "assets/img/photographer/photo_2021-01-31_11-57-05.jpg";
import image5 from "assets/img/photographer/photo_2021-01-31_11-57-11.jpg";
import image6 from "assets/img/photographer/photo_2021-01-31_11-57-14.jpg";



import HeaderLinks from "../../components/Header/HeaderLinks.js";


import styles from "../../assets/jss/material-kit-react/views/school.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const {...rest} = props;

  const images = [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image4,
      thumbnail: image4,
    },
    {
      original: image5,
      thumbnail: image5,
    },
    {
      original: image6,
      thumbnail: image6,
    },
  ];

  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks/>}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />

      <Parallax
        filter
        image={imageBG}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.textParallax}>Свадебная фотосъемка </h1>
              <h3 className={classes.textParallax}>В своей работе мы используем индивидуальный подход</h3>
            </GridItem>
          </GridContainer>
        </div>
        <img
          src={logo}
          className={classes.logo}
        />
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>

        <div className={classes.boxWhite}>
          <div>
            <h2 className={classes.bold}>Пакет <span className={classes.red}>«Эконом»</span></h2>
            <h4>Вы сами составляете список нужных услуг!</h4>
            <ul>

              <li> Предварительная встреча и консультация,помощь при выборе идеи съёмки и места для прогулки,консультации по планированию свадебного дня.
              </li>
              <li> Работа фотографа от 2 часов.</li>
              <li> Запись на наш USB(8-16 GB) или облако.</li>
              <li> 20 фото с ретушью и печатью в размере 10 Х 15</li>
              <li> В подарок :</li>
              <ul>
                <li>Изготовление 4-х картин с ретушью на холсте в размере 26 х 38</li>
                <li>4 фото с ретушью на магните в размере 15 х 20 </li>
              </ul>
            </ul>
            
              <Button color="rose" size="lg">
                можно заказать от 150$ <sup>*</sup>
              </Button>
           
          </div>
          
          <div>
            <h2 className={classes.bold}>Пакет <span className={classes.red}>«Стандарт»</span></h2>
            <h4>Вы сами составляете список нужных услуг!</h4>
            <ul>
              <li> Предварительная всчтеча и консультация,помощь при выборе идеи съёмки и места для прогулки,консультации по планированию свадебного дня. </li>
              <li> Работа фотографа до 8-и часов.</li>
              <li> Срок обработки до 30 дней (в зависимости от загруженности).</li>
              <li> Запись на наш USB(8-16 GB) или облако.</li>
              <li> 20 фото с ретушью и печатью в размере 10 Х 15.</li>
              <li> В подарок :</li>
              <ul>
                <li>Изготовление 4-х картин с ретушью на холсте в размере 26 х 38</li>
                <li>4 фото с ретушью на магните в размере 15 х 20</li>
              </ul>
            </ul>
            
              <Button color="rose" size="lg">
                можно заказать от 300$ <sup>*</sup>
              </Button>
            
          </div>
          <p><sup>*</sup>Все расчеты исключительно в белорусских рублях. Цена в иностранной валюте приведена справочно</p>
        </div>

      
        <div className={classes.boxWhite}>
          <h2 className={classes.bold}>Примеры нашей фотосъемки</h2>
          <GridContainer><GridItem xs={10} sm={10} md={10}><ImageGallery thumbnailPosition='right'
                                                                         items={images}/></GridItem></GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
