import  axios from 'axios';
import setAuthToken from "../utils/setAuthToken";
import {setAlert} from "./alert";

import { AUTH_ERROR, USER_ORDERS, POST_ORDER, USER_ORDERS_ERROR, POST_ORDER_ERROR, EDIT_ORDER, EDIT_ORDER_ERROR } from "../actions/types";

export const userOrders = (userId) => async dispatch =>{
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(`https://api.a-priori.by/api/order/userId/${userId}`);
      const data = res.data.filter(function(item) {
        return item._id !== '5f42e3b06bad6ccae16f9273';
      });
      dispatch({
        type:USER_ORDERS,
        payload: data
      });
    } catch (err) {
      dispatch({
        type: USER_ORDERS_ERROR
      })
    }
  } else {
    dispatch({
      type: AUTH_ERROR
    })
  }
};

export const postOrder = (userId, name, size, count, image, status = "Оформлен", price, phone) => async dispatch => {
  const config = {
    headers:{
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ user:userId, name:name, size:size, count:count, image:image, status:status, price:price, phone: phone});

  try {
    const res = await axios.post('https://api.a-priori.by/api/order', body, config);

    dispatch({
      type: POST_ORDER,
      payload: res.data
    });
    
    dispatch(userOrders(userId));
    
    dispatch(setAlert('Ваш заказаз сформирован', 'success'));

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors){
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ORDER_ERROR
    });
  }
};


export const editOrder = (userId, orderId, status = "В работе") => async dispatch => {
  const config = {
    headers:{
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ status:status});

  try {
    const res = await axios.post(`https://api.a-priori.by/api/order/orderId/${orderId}`, body, config);

    dispatch({
      type: EDIT_ORDER,
      payload: res.data
    });

    dispatch(userOrders(userId));

    dispatch(setAlert('Заказ изменен', 'success'));

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors){
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: EDIT_ORDER_ERROR
    });
  }
};
