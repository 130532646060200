import React, { useState, useEffect, createRef, useRef } from 'react';
import {connect} from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Select from 'react-select';
import chroma from 'chroma-js';
import { v1 as uuidv1 } from 'uuid';
import { Stage, Layer, Image} from 'react-konva';
import useImage from 'use-image';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ReactDOM from "react-dom";



import Schemas from './Schemas';
import DynamicImage from '../elements/DynamicImage';
import DynamicText from '../elements/DynamicText';
import Button from "components/CustomButtons/Button.js";
import styles from "../../../assets/jss/material-kit-react/views/ConstructorPage";

import elem1 from "assets/img/constructorElements/children-element.png";
import elem2 from "assets/img/constructorElements/el-circle-min.png";
import elem3 from "assets/img/constructorElements/el-florju.png";
import elem4 from "assets/img/constructorElements/el-s1200.png";
import elem5 from "assets/img/constructorElements/el-starfish.png";
import elem6 from "assets/img/constructorElements/el-text-love-story.png";
import elem7 from "assets/img/constructorElements/el-treancle-love-story.png";
import elem8 from "assets/img/constructorElements/imgElementsHappy.png";
import elem9 from "assets/img/constructorElements/imgElementsWedding.png";
import elem10 from "assets/img/constructorElements/el-fon.png";
import elem11 from "assets/img/constructorElements/el-fon2.png";
import elem12 from "assets/img/constructorElements/el-flover.png";
import elem13 from "assets/img/constructorElements/el-decorative.png";
import elem14 from "assets/img/constructorElements/el-1.png";
import elem15 from "assets/img/constructorElements/el-2.png";
import elem16 from "assets/img/constructorElements/el-3.png";
import elem17 from "assets/img/constructorElements/el-4.png";
import elem18 from "assets/img/constructorElements/el-5.png";
import elem19 from "assets/img/constructorElements/el-6.png";
import elem20 from "assets/img/constructorElements/el-7.png";
import elem21 from "assets/img/constructorElements/el-8.png";
import elem22 from "assets/img/constructorElements/el-9.png";
import elem23 from "assets/img/constructorElements/el-10.png";
import elem24 from "assets/img/constructorElements/el-11.png";
import elem25 from "assets/img/constructorElements/el-12.png";
import elem26 from "assets/img/constructorElements/el-13.png";
import elem27 from "assets/img/constructorElements/el-14.png";
import elem28 from "assets/img/constructorElements/el-15.png";
import elem29 from "assets/img/constructorElements/el-16.png";
import elem30 from "assets/img/constructorElements/el-17.png";
import elem31 from "assets/img/constructorElements/el-18.png";
import elem32 from "assets/img/constructorElements/el-19.png";
import elem33 from "assets/img/constructorElements/el-20.png";
import elem34 from "assets/img/constructorElements/el-21.png";
import elem35 from "assets/img/constructorElements/el-22.png";
import elem36 from "assets/img/constructorElements/el-23.png";
import elem37 from "assets/img/constructorElements/el-24.png";
import elem38 from "assets/img/constructorElements/el-25.png";

const useStyles = makeStyles(styles);

export default function OneStage ( props ) {
    const classes = useStyles();
    const [selectedId, selectShape] = useState(null);
    const [stage, setStage] = useState({
        stageX: 0,
        stageY: 0,
        stageScale: 1,
        stageWidth: 900,
        stageHeight: 500,
    });
    const [Default, setDefault] = useState({
        height: 234,
        width: 346.5,
        x: 10,
        y: 10,
    });
    const [clipPosition, setClipPosition] = useState('center-middle');
    const [color, setColor] = useState('#ea4237');
    const [fontFamilyText, setFontFamilyText] = useState('Comic Sans MS');
    const [images, setImages] = useState([]);
    const [hiddenImages, setHiddenimages] = useState([]);
    const [text, setText] = useState([]);
    const [textValue, setTextValue] = useState('Нажми дважды');
    const fileUploadEl = createRef();
    const [, updateState] = useState();
    const [shapes, setShapes] = useState([]);
    const [position, setPosition] = useState({
        textX: 0,
        textY: 0
    });
    const [textEditVisible, setTextEditVisible] = React.useState(false);
    const stageRef = useRef();

    const schemaRef = useRef();

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target.index === 0;
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };

    useEffect(() => {
        selectShape(null);
        stageRef.current.children[0].children.map((item) => {
            if (item.attrs.clasName === 'custom-image' || item.attrs.clasName === 'custom-text') {
                item.hide();
            }
        });
      hiddenImages.map((item) => {
        item.show();
      });
      setHiddenimages([]);
    }, [props.background]);

    useEffect(()=> {
        selectShape(null);
    }, [props.unselect, props.activeSlide])
    const _onChange = (e) => {
        setClipPosition(e.value);
    };
    
    /// --- опции выбора
    const options = [
        { value: "center-middle", label: "По центру" },
        { value: "center-top", label: "По центру вверху" },
        { value: "center-bottom", label: "По центру внизу" },
        { value: "right-top", label: "Справа вверху" },
        { value: "right-middle", label: "Справа по середине" },
        { value: "right-bottom", label: "Справа внизу" },
        { value: "left-top", label: "Слева вверху" },
        { value: "left-middle", label: "Слева по середине" },
        { value: "left-bottom", label: "Слева снизу" },
    ];
    
    const optionsFontFamilyText = [
        { value: "Comic Sans MS", label: "Comic Sans MS" },
        { value: "Roboto", label: "Roboto" },
        { value: "Open Sans", label: "Open Sans" },
        { value: "Arial", label: "Arial" },
        { value: "Times New Roman", label: "Times New Roman" },
        { value: "Verdana", label: "Verdana" },
        { value: "Georgia", label: "Georgia" },
        { value: "Helvetica", label: "Helvetica" },
    ];
    
    const colourOptions = [
      { color: "#ea4237", label: "Вишня" },
      { color: "#b5203d", label: "Перец" },
      { color: "#832e38", label: "Каштан" },
      { color: "#9848b2", label: "Слива" },
      { color: "#713b94", label: "Баклажан" },
      { color: "#922194", label: "Виноград" },
      { color: "#a7679b", label: "Орхидея" },
      { color: "#af9ccc", label: "Лаванда" },
      { color: "#f884a2", label: "Пудра" },
      { color: "#d90e80", label: "Клубника" },
      { color: "#f485b9", label: "Роза" },
      { color: "#f7018e", label: "Маджента" },
      { color: "#fc7977", label: "Лососевый" },
      { color: "#fe7418", label: "Мандарин" },
      { color: "#eca66a", label: "Дыня" },
      { color: "#f8d20c", label: "Банан" },
      { color: "#f6de1a", label: "Лимон" },
      { color: "#c9e05b", label: "Яблоко" },
      { color: "#66bd4a", label: "Лайм" },
      { color: "#00b44b", label: "Зелень" },
      { color: "#038a5a", label: "Клевер" },
      { color: "#007252", label: "Папоротник" },
      { color: "#3ca243", label: "Мох" },
      { color: "#adcc39", label: "Салатовый" },
      { color: "#62c5b2", label: "Цвет волны" },
      { color: "#84d2cc", label: "Мятный" },
      { color: "#008daa", label: "Сизый" },
      { color: "#008ed0", label: "Небесный" },
      { color: "#04c6b7", label: "Бирюзовый" },
      { color: "#fff", label: "Белый" },
      { color: "#fffef0", label: "Слоновыя кость" },
      { color: "#000", label: "Черный" },
      { color: "#04538a", label: "Синий" },
      { color: "#262951", label: "Темно-синий" },
      { color: "#888d91", label: "Серый" },
      { color: "#413f42", label: "Темно-серый" },
      { color: "#8c92ac", label: "Другой-серый" },
    ];
    
    /// --- функция выбора цвета
    const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  
    const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
    
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
  
    /// ------------------------------
    /// --- функция подгрузки картинки
    const addLeftImage = e => {
        const id = uuidv1();
        let img = document.createElement("img");
        img.setAttribute("src", e.target.src);
        let { width, height } = img;
        while (width >= 500 || height > 500) {
            width /= 2;
            height /= 2;
        }
        images.push({
            content: e.target.src,
            width: width,
            height: height,
            x: Default.x,
            y: Default.y,
            id,
          
        });
        setImages(images);
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
        selectShape(id);
        setClipPosition("center-top");
    };
    const drawImage = () => {
        fileUploadEl.current.click();
    };
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const fileChange = ev => {
        let file = ev.target.files[0];
        let reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                const id = uuidv1();
                if(selectedId) {
                    const item = images.slice();
                    const index = item.findIndex( (item) => {
                        if (item.id === selectedId) return item
                    });
                    images[index] = {
                        ...images[index],
                        content: reader.result,
                    };
                } else {
                    images.push({
                        content: reader.result,
                        width: Default.width,
                        height: Default.height,
                        x: Default.x,
                        y: Default.y,
                        id,
                    });
                    if (schemaRef.current) {
                      schemaRef.current.hide();//not need to destroy
                      hiddenImages.push(schemaRef.current);
                      setHiddenimages(hiddenImages);
                    }
                    setImages(images);
                    fileUploadEl.current.value = null;
                    shapes.push(id);
                    setShapes(shapes);
                }
                forceUpdate();
                setDefault({
                    height: 234,
                    width: 346.5,
                    x: 190,
                    y: 150,
                });
                selectShape(id);
                setClipPosition("center-top");
            },
            false
        );
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleUpdateImage = e => {
        const element = e.currentTarget.attrs;
        forceUpdate();
        setDefault({
            width: element.width,
            height: element.height,
            x: element.x,
            y: element.y
        });
        setClipPosition("center-top");
    };
    const handleTextCreate = () => {
        const id = uuidv1();
        text.push({
            content: textValue,
            // size: sizeText,
            color: color,
            style: 'bold',
            fontFamily: fontFamilyText,
            id,
        });
        setText(text);
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
    };
    const handleTextDblClick = e => {
        const item = text.slice();
        item.find( (item) => {
            if (item.id === selectedId) setTextValue(item.content);
        });
        const absPos = e.target.getAbsolutePosition();
        setPosition({
            textX: absPos.x,
            textY: absPos.y
        });
        setTextEditVisible(true);
    };
    const handleTextEdit = e => {
        setTextValue(e.target.value);
    };
    const handleTextareaKeyDown = e => {
        if (e.keyCode === 13) {
            setTextEditVisible(false);
            const item = text.slice();
            const index = item.findIndex( (item) => {
                if (item.id === selectedId) return item
            });
            text[index] = {
                ...text[index],
                content: textValue,
            };
            setText(text);
        }
    };
    const handleRemove = () => {
        if (selectedId) {
            const elem = stageRef.current.children[0].children;
            elem.map((item) => {
                if (item.attrs.id === selectedId) item.destroy();
            });
        }
    };
    const _onChangeColor = (e) => {
        setColor(e.color);
        const item = text.slice();
        const index = item.findIndex( (item) => {
            if (item.id === selectedId) return item
        });
        text[index] = {
            ...text[index],
            color: e.color,
        };
        setText(text);
    };
    const _onChangeFontFamilyText = (e) => {
        setFontFamilyText(e.value);
        const item = text.slice();
        const index = item.findIndex( (item) => {
            if (item.id === selectedId) return item
        });
        text[index] = {
            ...text[index],
            fontFamily: e.value,
        };
        setText(text);
    };

    //part for text
    const returnPosition = (name, type, width, height) => {
        let position = {x:10, y:10};
        switch(name) {
          case 'left-bottom':
            position = {x: width/8, y: height-height/5};
            break;
          case 'right-bottom':
            position = {x: width/2+width/8, y: height-height/5};
            break;
          case 'left-top':
            position = {x: width/8, y: height/5};
            break;
          case 'right-top':
            position = {x: width/2+width/8, y: height/5};
            break;
          case 'center-bottom':
            position = {x: width/4, y: height-height/5};
            break;
          case 'center-top':
            position = {x: width/4, y: height/5};
            break;
        }
        if (type==='x'){
          return position.x;
        } else{
          return position.y;
        }
    };
  
    // useEffect(() => {
    //     if (props.background){
    //         text.map(item => {
    //             selectShape(item.id);
    //         })
    //     }
    // }, [props.background]);
  
    useEffect(() => {
        props.tRef(stageRef);
    }, [stageRef]);
    
    useEffect(() => {
    if (props.text) {
      const id = uuidv1();
      const textPosition = props.textPosition;
      const x =
      text.push({
        content: props.text,
        color: 'black',
        style: 'bold',
        fontFamily: fontFamilyText,
        x:returnPosition(textPosition,'x',props.width,props.height),
        y:returnPosition(textPosition,'y',props.width,props.height),
        width: props.width/3,
        id,
      });
      setText(text);
    }
    }, [props.text, props.textPosition]);
    
    const URL = props.background ? props.background: "";
    const [imageBackground] = useImage(URL);
    
    return (
      <div id="oneStage"  className={classes.boxConstructor + ' ' + props.class} >
        <div  className={classes.navigationButtons }>
          <div>
            <Button color="rose" endIcon={<PostAddIcon />} onClick={handleTextCreate}>Добавить текст</Button>
            <Button color="rose" endIcon={<AddAPhotoIcon />} onClick={() => {drawImage(); selectShape(null);}}>Добавить картинку</Button>
          </div>
          <div>
            <Button endIcon={<DeleteForeverIcon />} onClick={()=> { handleRemove(); selectShape(null)}}>Удалить элемент</Button>
            <input
              style={{ display: "none" }}
              type="file"
              ref={fileUploadEl}
              onChange={fileChange}
            />
          </div>
        </div>
        <div className={classes.navigationElements}>
            <div className={classes.SelectStyle}>
              <i>Положение картинки</i>
              <Select
                defaultValue={options[0]}
                options={options}
                onChange={e => _onChange(e)}
                isMulti={false}
                closeMenuOnSelect={true}
              />
            </div>
            <div className={classes.SelectStyleMin}>
              <i>Цвет текста</i>
              <Select
                options={colourOptions}
                defaultValue={colourOptions[1]}
                styles={colourStyles}
                onChange={e => _onChangeColor(e)}
                isMulti={false}
                closeMenuOnSelect={true}
              />
            </div>
            <div className={classes.SelectStyleMin}>
              <i>Тип шрифта</i>
              <Select
                defaultValue={optionsFontFamilyText[0]}
                options={optionsFontFamilyText}
                onChange={e => _onChangeFontFamilyText(e)}
                isMulti={false}
                closeMenuOnSelect={true}
              />
            </div>
          <textarea
            value={textValue}
            style={{
              display: textEditVisible ? 'block' : 'none',
              position: 'absolute',
              top: position.textY + 50 + 'px',
              left: position.textX + 240 + 'px',
              zIndex: 9999,
              width: "290px",
              minHeight: "56px",
              fontSize: "18px",
            }}
            onChange={handleTextEdit}
            onKeyDown={handleTextareaKeyDown}
          />
          <div className={classes.addIlements}>
              <img src={elem1} onClick={addLeftImage} />
              <img src={elem2} onClick={addLeftImage} />
              <img src={elem3} onClick={addLeftImage} />
              <img src={elem4} onClick={addLeftImage} />
              <img src={elem5} onClick={addLeftImage} />
              <img src={elem6} onClick={addLeftImage} />
              <img src={elem7} onClick={addLeftImage} />
              <img src={elem8} onClick={addLeftImage} />
              <img src={elem9} onClick={addLeftImage} />
              <img src={elem10} onClick={addLeftImage} />
              <img src={elem11} onClick={addLeftImage} />
              <img src={elem12} onClick={addLeftImage} />
              <img src={elem13} onClick={addLeftImage} />
              <img src={elem14} onClick={addLeftImage} />
              <img src={elem15} onClick={addLeftImage} />
              <img src={elem16} onClick={addLeftImage} />
              <img src={elem17} onClick={addLeftImage} />
              <img src={elem18} onClick={addLeftImage} />
              <img src={elem19} onClick={addLeftImage} />
              <img src={elem20} onClick={addLeftImage} />
              <img src={elem21} onClick={addLeftImage} />
              <img src={elem22} onClick={addLeftImage} />
              <img src={elem23} onClick={addLeftImage} />
              <img src={elem24} onClick={addLeftImage} />
              <img src={elem25} onClick={addLeftImage} />
              <img src={elem26} onClick={addLeftImage} />
              <img src={elem27} onClick={addLeftImage} />
              <img src={elem28} onClick={addLeftImage} />
              <img src={elem29} onClick={addLeftImage} />
              <img src={elem30} onClick={addLeftImage} />
              <img src={elem31} onClick={addLeftImage} />
              <img src={elem32} onClick={addLeftImage} />
              <img src={elem33} onClick={addLeftImage} />
              <img src={elem34} onClick={addLeftImage} />
              <img src={elem35} onClick={addLeftImage} />
              <img src={elem36} onClick={addLeftImage} />
              <img src={elem37} onClick={addLeftImage} />
              <img src={elem38} onClick={addLeftImage} />
          </div>
          
        </div>
        
        <Stage
          className={classes.stageElements}
          x={stage.stageX}
          y={stage.stageY}
          scaleX={stage.stageScale}
          scaleY={stage.stageScale}

          width={props.width}
          height={props.height}
          
          ref={stageRef}
          
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          style={{
              width: props.width,
              height: props.height,
          }}
        >
        <Layer>
          <Image width={props.width} height={props.height} image={imageBackground} fill={props.background} x={0} y={0} />
          <Schemas
            schema={props.schema}
            width={props.width}
            height={props.height}
            onClick={(e) => {fileUploadEl.current.click(); selectShape(null); handleUpdateImage(e); schemaRef.current = e.target;}}
            // onDragStart={(e) => {e.target.moveToTop();}}
            text={props.text}
            text2={props.text2}
          />
          { images.map((element, i) => (
            <DynamicImage
              key={i}
              imageUrl={element.content}
              shapeProps={element}
              isSelected={element.id === selectedId}
              onSelect={() => {
                selectShape(element.id);
                
              }}
              onChange={(newAttrs) => {
                const item = images.slice();
                item[i] = newAttrs;
                setImages(item);
              }}
              clipPosition={clipPosition}
              handleImageDblClick={(e)=> {
                  fileUploadEl.current.click();
                  handleUpdateImage(e);
              }}
            />
              )) }
          { text.map((element, i) => (
            <DynamicText
              key={i}
              text={element.content}
              shapeProps={element}
              isSelected={element.id === selectedId}
              onSelect={() => {
                  selectShape(element.id);
              }}
              onChange={(newAttrs) => {
                  const item = text.slice();
                  text[i] = newAttrs;
                  setText(item);
              }}
              fontFamily={element.fontFamily}
              fontColor={element.color}
              fontSize={26}
              fontStyle={element.style}
              handleTextDblClick={handleTextDblClick}
            />
            )
          ) }
        </Layer>
        </Stage>
      </div>
    );
};

