import React, {useEffect, useRef} from 'react';
import { Text, Transformer } from 'react-konva';

const DynamicText = ({shapeProps, isSelected, onSelect, onChange, fontFamily, fontSize, fontColor, text, fontStyle, handleTextDblClick}) => {
    
    const shapeRef = useRef();
    const trRef = useRef();
    
    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);
    
    return (
        <React.Fragment>
            <Text
                clasName={'custom-image'}
                text={text}
                fontSize={fontSize}
                fontFamily={fontFamily}
                onDblClick={handleTextDblClick}
                ref={shapeRef}
                fill={fontColor}
                onClick={onSelect}
                onTap={onSelect}
                fontStyle={fontStyle}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();

                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: Math.max(node.width() * scaleX, 20),
                    });
                }}
            />
            {isSelected && (
            <Transformer
                ref={trRef}
                padding={5}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 20) {
                        return oldBox;
                    }
                    return newBox;
                }}
            />
        )}
        </React.Fragment>
    );
};

export default DynamicText;
