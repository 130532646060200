import React from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import SnackbarContent from '../Snackbar/SnackbarContent'

const Alert = ({ alerts }) => alerts !== null && alerts.length > 0 && alerts.map(alert => {
    return(
      <div key={alert.id}>
          <SnackbarContent
              message={
                  <span>{alert.msg}</span>
              }
              close
              color={alert.alertType}
              icon="info_outline"
          />
      </div>  
    );
});

Alert.propTypes = {
    alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
   alerts: state.alert 
});

export default connect(mapStateToProps)(Alert);
