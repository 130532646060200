import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';

import HeaderLinks from "../../components/Header/HeaderLinks.js";

import imageBG from "assets/img/school/kindergarten/bg3.jpg";
import image1 from "assets/img/school/kindergarten/img_2058.jpg";
import image2 from "assets/img/school/kindergarten/img_2056.jpg";
import image3 from "assets/img/school/kindergarten/img_2055.jpg";
import image5 from "assets/img/school/kindergarten/img_2058.jpg";
import image6 from "assets/img/school/kindergarten/vin.jpg";
import logo from "assets/img/logo.png";

import styles from "../../assets/jss/material-kit-react/views/school.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const images = [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image5,
      thumbnail: image5,
    },
  ];
  const images2 = [
    {
      original: image6,
      thumbnail: image6,
    },
  ];

  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />

      <Parallax
        filter
        image={imageBG}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.textParallax}>Выпускные альбомы   для детского сада</h1>
            </GridItem>
          </GridContainer>
        </div>
        <img
          src={logo}
          className={classes.logo}
        />
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.boxGrey}>
          <div className={classes.container}>
            <h4 className={classes.textCenter}>Это первый коллективный альбом вашего малыша — он будет вместе с ним всю жизнь. Фотоальбом станет идеальным подарком для детей и родителей при окончании детского сада. Ребенок не порвет его и не испортит. Даже если он нечаянно заляпает фотоальбом красками – вы легко это исправите,
              протерев страницы фотокниги влажной тряпочкой.</h4>
          </div>
        </div>

        <div className={classes.boxWhite}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Выпускной альбом <br/> <span className={classes.red}>«До свидания, детский сад»</span></h2>
              <h4>Яркий детский альбом с веселыми  фотографиями.</h4>
              <h3 className={classes.red}>30 руб</h3>
              <h4>В стоимость выпускного альбома входит:</h4>
              <ul>
                <li>Портретная и групповая фотосъемка</li>
                <li>Обработка фотографий и верстка макета</li>
                <li>Согласование макета перед печатью</li>
                <li>Сопровождение макета менеджером</li>
                <li>Печать альбомов</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать альбом детский сад*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxGrey}>
          <GridContainer>

            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Двусторонняя виньетка</h2>
              <h4>виньетка на формате А4,  двусторонняя печать, матовая ламинация.</h4>
              <h3 className={classes.red}>15 руб</h3>
              <h4>В стоимость выпускного альбома входит:</h4>
              <ul>
                <li>Портретная и групповая фотосъемка</li>
                <li>Обработка фотографий и верстка макета</li>
                <li>Согласование макета перед печатью</li>
                <li>Сопровождение макета менеджером</li>
                <li>Печать альбомов</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать двустороннюю виньетку*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images2} />
            </GridItem>
          </GridContainer>
        </div>
        
        <div className={classes.boxWhite}>
          <h2 className={classes.bold} style={{paddingBottom: "20px"}}>Что будем делать в процессе?</h2>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>1</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Проведём две фотосъёмки
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>2</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Отберём лучшие кадры
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>3</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Применим ретушь ко всем фото
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>4</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Согласуем с вами макет
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>5</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Изготовим фотоальбом (учителю в подарок)
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>6</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Доставим вам продукцию
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxWhite}>
          <h2 className={classes.bold}>Примеры нашей фотосъемки</h2>
          <GridContainer><GridItem xs={10} sm={10} md={10}><ImageGallery thumbnailPosition='right' items={images} /></GridItem></GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
