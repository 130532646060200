import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import SectionAboutUs from "../HomePage/Sections/SectionAboutUs.js";
import SectionQuestion from "../HomePage/Sections/SectionQuestion.js";
import SectionBooking from "../HomePage/Sections/SectionBooking.js";
import SectionCanvas from "./Sections/SectionCanvas.js";
import Parallax from "../../components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function CanvasPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small
                filter
                image={
                  "https://w-dog.ru/wallpapers/12/19/436089275281828/zhivopis-kartina-xolst-kraski-yarko-abstrakciya.jpg"
                }
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionCanvas history={props.history}/>
      </div>
      <div className={classNames(classes.main, classes.mainBg)}>
        <SectionAboutUs />
        <SectionBooking />
        <SectionQuestion />
      </div>
      <Footer />
    </div>
  );
}
