import {container, title} from "assets/jss/material-kit-react.js";

const productStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    position: "relative",
    ...container
  },
  section: {
    padding: "0 0 70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  titleWhile: {
    ...title,
    marginBottom: "0",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#fff"
  },
  descriptionWhile: {
    color: "#fff",
    marginBottom: "50px",
  },
  description: {
    color: "#999"
  },
  border: {
    color: "#999",
    "&:not(:first-child)": {
      borderLeft: "1px solid #fff"
    }
  },
  bgSectionBooking: {
    backgroundSize: "cover",
    padding: "70px 0",
    position: "relative",
    background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
    backgroundColor: "#343434",
    backgroundPosition: "50%",
    "&:after": {
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      content: "''",
      display: "block",
      zIndex: "1",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    }
  }
};

export default productStyle;
