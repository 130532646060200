export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';
export const LOGOUT_GOOGLE = 'LOGOUT_GOOGLE';

export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE__FAIL = 'MESSAGE__FAIL';

export const USER_ORDERS = 'USER_ORDERS';
export const USER_ORDERS_ERROR = 'USER_ORDERS_ERROR';

export const POST_ORDER = 'POST_ORDER';
export const POST_ORDER_ERROR = 'POST_ORDER_ERROR';

export const EDIT_ORDER = 'EDIT_ORDER';
export const EDIT_ORDER_ERROR = 'EDIT_ORDER_ERROR';

export const POST_IMAGE = 'POST_IMAGE';
export const POST_IMAGE_ERROR = 'POST_IMAGE_ERROR';

export const UPDATED_USER_PHONE = 'UPDATED_USER_PHONE';
export const ERROR_USER_PHONE = 'ERROR_USER_PHONE';
