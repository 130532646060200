import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";

import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';

import HeaderLinks from "../../components/Header/HeaderLinks.js";
import styles from "../../assets/jss/material-kit-react/views/school.js";

import {connect} from "react-redux";

// import schoolInfo from "./schoolSection/schoolInfo.js";
import cup1 from "assets/img/cup/1.jpg";
import cup2 from "assets/img/cup/2.jpg";
import cup3 from "assets/img/cup/3.jpg";
import cup4 from "assets/img/cup/4.jpg";
import cup5 from "assets/img/cup/5.jpg";
import cup6 from "assets/img/cup/6.jpg";
import cup7 from "assets/img/cup/7.jpg";
import cup8 from "assets/img/cup/8.jpg";
import cup9 from "assets/img/cup/9.jpg";
import cup10 from "assets/img/cup/10.jpg";
import cup11 from "assets/img/cup/11.jpg";
import cup12 from "assets/img/cup/12.png";
import cup14 from "assets/img/cup/14.jpg";
import cup15 from "assets/img/cup/15.jpg";
import cup16 from "assets/img/cup/16.jpg";
import cup17 from "assets/img/cup/17.jpg";
import cup18 from "assets/img/cup/18.jpg";
import cup19 from "assets/img/cup/19.png";
import cup20 from "assets/img/cup/20.png";
import cup21 from "assets/img/cup/21.jpg";
import cup22 from "assets/img/cup/22.jpg";
import cup23 from "assets/img/cup/23.jpg";
import cup24 from "assets/img/cup/24.jpg";
import logo from "assets/img/logo.png";


const useStyles = makeStyles(styles);

const Components = ({ isAuthenticated, props}) => {
  const classes = useStyles();
  const { ...rest } = props;

  const imgMap = [
    {class:"small",ID: "id", URL: `url('${cup1}')`},
    {class:"small",ID: "id", URL: `url('${cup2}')`},
    {class:"small",ID: "id", URL: `url('${cup3}')`},
    {class:"small",ID: "id", URL: `url('${cup4}')`},
    {class:"small",ID: "id", URL: `url('${cup5}')`},
    {class:"small",ID: "id", URL: `url('${cup6}')`},
    {class:"small",ID: "id", URL: `url('${cup7}')`},
    {class:"small",ID: "id", URL: `url('${cup8}')`},
    {class:"small",ID: "id", URL: `url('${cup9}')`},
    {class:"small",ID: "id", URL: `url('${cup10}')`},
    {class:"small",ID: "id", URL: `url('${cup11}')`},
    {class:"small",ID: "id", URL: `url('${cup12}')`},
    {class:"small",ID: "id", URL: `url('${cup14}')`},
    {class:"small",ID: "id", URL: `url('${cup15}')`},
    {class:"small",ID: "id", URL: `url('${cup16}')`},
    {class:"small",ID: "id", URL: `url('${cup17}')`},
    {class:"small",ID: "id", URL: `url('${cup18}')`},
    {class:"small",ID: "id", URL: `url('${cup19}')`},
    {class:"small",ID: "id", URL: `url('${cup20}')`},
    {class:"small",ID: "id", URL: `url('${cup21}')`},
    {class:"small",ID: "id", URL: `url('${cup22}')`},
    {class:"small",ID: "id", URL: `url('${cup23}')`},
    {class:"small",ID: "id", URL: `url('${cup24}')`},
  ];

  const images = [
    {
      original: 'https://kitchen.cdnvideo.ru/wp-content/uploads/2015/06/tochechnyj-risunok-na-kruzhke-7-798x518.jpg',
      thumbnail: 'https://kitchen.cdnvideo.ru/wp-content/uploads/2015/06/tochechnyj-risunok-na-kruzhke-7-798x518.jpg',
    },
    {
      original: 'https://iremont.tv/upload/medialibrary/c66/c665b9319d0345ba78b43e185f05b25f.jpg',
      thumbnail: 'https://iremont.tv/upload/medialibrary/c66/c665b9319d0345ba78b43e185f05b25f.jpg',
    },
    {
      original: 'https://www.fatline.com.ua/images/content/kak_sozdat_originalnuyu_kruzhku-3.jpg',
      thumbnail: 'https://www.fatline.com.ua/images/content/kak_sozdat_originalnuyu_kruzhku-3.jpg',
    },
  ];

  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />

      <Parallax
        filter
        image={cup11}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.textParallax}>Печать на кружке</h1>
            </GridItem>
          </GridContainer>
        </div>
        <img
          src={logo}
          className={classes.logo}
        />
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>

        <div className={classes.boxWhite}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Печать на кружке</h2>
              <h3 className={classes.red}>от 12 руб</h3>
              <h4>Характеристики</h4>
              <ul>
                <li>Материал: керамика класса А.</li>
                <li>Размер изделия: 9 х 8см (высота, диаметр).</li>
                <li>Объем 300мл.</li>
                <li>Фирменная картонная упаковка.</li>
                <li>Вес 410 грамм.</li>
              </ul>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxGrey}>
          <h2 className={classes.bold}><span className={classes.red}>Выберите наклейку</span></h2>
          <ul className={classes.box}>
            {
              imgMap.map((i)=>{
                return (
                  <li className={classes.boxLi}>
                    <div className={classes.boxCup} id={i.ID} style={{backgroundImage: `${i.URL}`}}></div>
                  </li>
                )
              })
            }
          </ul>
          <br/><br/>
          {/*<Tooltip*/}
            {/*id="tooltip-left"*/}
            {/*title={ isAuthenticated ? "" : "Пожалуйста войдите в профиль"}*/}
            {/*placement="bottom"*/}
          {/*>*/}
            {/*<Button*/}
              {/*color={ isAuthenticated ? "rose" : ""}*/}
              {/*size="lg"*/}
              {/*// onClick={(e)=> onSubmit(e)}*/}
            {/*>Заказать печать</Button>*/}
          {/*</Tooltip>*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};
Components.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
});


export default connect(mapStateToProps)(Components)
