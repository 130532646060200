import {container} from "assets/jss/material-kit-react.js";

const schoolStyle = {
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-120px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  mainBg: {
    margin: "30px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  boxGrey: {
    background: "#eee",
    color: "#000",
    padding: "4em 2em"
  },
  boxWhite: {
    background: "#fff",
    color: "#000",
    padding: "4em 2em"
  },
  red: {
    color: "#e91e63"
  },
  bold: {
    fontWeight: "bold",
    lineHeight: "42px",
  },
  circle: {
    width: "40px",
    height: "40px",
    background: "#e91e63",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "40px",
    display: "block",
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold"
  },
  textParallax: {
    color: "rgb(255, 255, 255)",
    position: "relative",
    zIndex: "12",
    fontWeight: "500",
    fontStyle: "italic",
  },
  flex:{
    display: "flex"
  },
  box:{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  boxLi:{
    display: "block",
    margin: "15px 0"
  },
  boxCup:{
    width: "220px",
    height: "100px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "0 2px 12px rgba(233, 30, 99, 0.4)",
  },
  logo: {
    position: "absolute",
    right: "30px",
    zIndex:"3",
    bottom: "180px",
    width: "100px"
  },
};

export default schoolStyle;
