import React, {useState} from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { login } from "../../actions/auth";
import { signGoogle } from "../../actions/auth";
import Alert from "../../components/Snackbar/Alert";
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import CachedIcon from '@material-ui/icons/Cached';
// import People from "@material-ui/icons/People";
import { Link } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";



const useStyles = makeStyles(styles);

const LoginPage = (props) => {

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  }); 
  
  const [loading, setLoading] = useState(false);

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({...formData, [e.target.id]: e.target.value});
  };
  
  const onSubmit = async e =>{
    e.preventDefault();
    let new_email = email.toLocaleLowerCase();

    props.login({ email: new_email, password });
    
    setLoading(true);
    
    setTimeout(()=>{
      if (!props.isAuthenticated){
        setLoading(false);
      }
    },5500);
  };

  const onSubmitGoogle = async e =>{
    props.signGoogle(e);

    setLoading(true);

    setTimeout(()=>{
      if (!props.isAuthenticated){
        setLoading(false);
      }
    },5500);
  };

  if (props.isAuthenticated){
    props.history.push('/');
  }
  
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.h4}>АВТОРИЗАЦИЯ</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                        onChange: (e) => onChange(e)
                      }}
                      inputProps={{
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                      value={email}
                    />
                    <CustomInput
                      labelText="Пароль"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                        onChange: (e) => onChange(e)
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                      value={password}
                    />
                  </CardBody>
                  <Alert/>
                  <CardFooter className={classes.cardFooter}>
                    <GridContainer  justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Button color="rose" 
                          onClick={(e)=> onSubmit(e)}
                          disabled={loading}
                        >
                          {loading && <i className="fa-spin"><CachedIcon style={{margin: "0"}} /></i> }
                          АВТОРИЗАЦИЯ
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Link to={"/registration"} className={classes.link}>
                          <Button simple color="primary" size="sm">
                            Регистрация
                          </Button>
                        </Link>
                      </GridItem>
                      <GridItem>
                        <h6 className={classes.h6} style={{color: "#9c27b0"}}>ИЛИ ВОЙДИТЕ С ПОМОЩЬЮ</h6>
                      </GridItem>
                      <GridItem>
                        <GoogleLogin
                          clientId="640149440867-u9j8f1n8q7q0lalj1bu0jr1a8ljp34iq.apps.googleusercontent.com"
                          buttonText="Log in with Google"
                          onSuccess={onSubmitGoogle}
                          onFailure={onSubmitGoogle}
                          cookiePolicy={'single_host_origin'}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  signGoogle: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated
});

export  default connect(mapStateToProps, { setAlert, login, signGoogle })(LoginPage);
