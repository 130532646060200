import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {editOrder, userOrders} from "../../actions/orders"

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import SelectStatus from "./SelectStatus"

const useStyles = makeStyles(styles);

const TableOrder = (props) => {
    const classes = useStyles();
    const {auth, editOrder, rows} = props;
    const changeStatus = (idOrder,newStatus="В работе") =>{

        if (auth&&auth._id){
            editOrder(auth._id,idOrder,newStatus);
        }
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Размер</TableCell>
                            <TableCell align="left">Номер заказа</TableCell>
                            <TableCell align="left">Статус</TableCell>
                            <TableCell align="left">Дата заказа</TableCell>
                            <TableCell align="left">Телефон</TableCell>
                            <TableCell align="left">Цена</TableCell>
                            <TableCell align="left">Оплата</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {rows.map((row) => (
                            <TableRow key={row._id}>
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="left">{row.size}</TableCell>
                                <TableCell align="left">Номер №{row.order_number ? row.order_number : "0"}</TableCell>
                                <TableCell align="left">
                                    {/*<FormControl >*/}
                                        {/*{row.status}*/}
                                    {/*</FormControl>*/}

                                    <SelectStatus value={row.status} onChange={(status)=> changeStatus(row._id,status)}/>

                                </TableCell>
                                <TableCell align="left">{new Date(row.date).toLocaleDateString()} {new Date(row.date).getHours()}:{new Date(row.date).getMinutes()}</TableCell>
                                <TableCell align="left">
                                  {row.phone ? row.phone : "Нет номера"}
                                  </TableCell>
                                <TableCell align="left">{row.price?row.price:0} руб.</TableCell>
                                <TableCell align="left">
                                  {row.payment
                                    ? <span className={classes.green}>Оплачено</span>
                                    :
                                    <div>
                                      <span className={classes.red}>Не оплачено </span><br/>
                                    </div>
                                  }
                                  </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};


TableOrder.defaultProps = {
  rows:[]
};

TableOrder.propTypes = {
  auth: PropTypes.object.isRequired,
  rows:PropTypes.array,
  editOrder:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    auth: state.Auth
});

export default connect(
    mapStateToProps,
    { editOrder }
)(TableOrder);
