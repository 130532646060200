import { USER_ORDERS } from "../actions/types";

const initialState = {
  orders: []
};

export default function (state = initialState, { type, payload } ) {
  switch(type) {
    case USER_ORDERS:
      return {
        ...state,
        orders:payload
      };
    default:
      return state;
  }
}
