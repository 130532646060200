import React , {useRef, useEffect} from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

const DynamicImage = ({ shapeProps, isSelected, onSelect, onChange, clipPosition, imageUrl, handleImageDblClick}) => {
    const [crop, setCrop] = React.useState(null);


    const shapeRef = useRef();
    const trRef = useRef();
    const [image] = useImage(imageUrl);


    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
            if (trRef.current) {
                getCrop(trRef.current.children[0], clipPosition);
            }
        }
    }, [isSelected]);
    
    useEffect(()=> {
        if (trRef.current) {
            getCrop(trRef.current.children[0], clipPosition);
        }
    }, [clipPosition]);

    const getCrop = (node, clipPosition = 'center-middle') => {
        const width = node.attrs.width;
        const height = node.attrs.height;
        const aspectRatio = width / height;
        let newWidth;
        let newHeight;

        let img = document.createElement("img");
        img.setAttribute("src", imageUrl);
        let imageNew = img;
        
        const imageRatio = imageNew.width / imageNew.height;

        if (aspectRatio >= imageRatio) {
            newWidth = imageNew.width;
            newHeight = imageNew.width / aspectRatio;
        } else {
            newWidth = imageNew.height * aspectRatio;
            newHeight = imageNew.height;
        }


        if (clipPosition === 'left-top') {
            setCrop({
                x: 0,
                y: 0,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'left-middle') {
            setCrop({
                x: 0,
                y: (imageNew.height - newHeight) / 2,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'left-bottom') {
            setCrop({
                x: 0,
                y: imageNew.height - newHeight,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'center-top') {
            setCrop({
                x: (imageNew.width - newWidth) / 2,
                y: 0,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'center-middle') {
            setCrop({
                x: (imageNew.width - newWidth) / 2,
                y: (imageNew.height - newHeight) / 2,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'center-bottom') {
            setCrop({
                x: (imageNew.width - newWidth) / 2,
                y: (imageNew.height - newHeight),
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'right-top') {
            setCrop({
                x: (imageNew.width - newWidth),
                y: 0,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'right-middle') {
            setCrop({
                x: (imageNew.width - newWidth),
                y: (imageNew.height - newHeight) / 2,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'right-bottom') {
            setCrop({
                x: imageNew.width - newWidth,
                y: imageNew.height - newHeight,
                width : newWidth,
                height : newHeight
            });
        } else if (clipPosition === 'scale') {
            setCrop({
                x: (imageNew.width - newWidth) / 2,
                y: (imageNew.height - newHeight) / 2,
                width : width,
                height : height
            });
        } else {
            console.error(
                new Error('Unknown clip position property - ' + clipPosition)
            );
        }
    };

    return (
        <React.Fragment>
            <Image
                clasName={'custom-image'}
                image={image}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                crop = {crop}
                draggable
                // onDragStart={(e) => {e.target.moveToTop();}}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
                onDblClick={handleImageDblClick}

            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        getCrop(trRef.current.children[0], clipPosition);
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default DynamicImage;
