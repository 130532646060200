import {container} from "assets/jss/material-kit-react.js";

const ConstructorPage = {
  container,
  boxConstructor: {
    display: 'flex',
    alignItems: "flex-start",
    flexWrap: "wrap",
    paddingTop: "0",
    justifyContent: "center",
  },
  onlyPreview:{
      display: "none",
  },
  positionCenterImage: {
    width: '100%',
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center'
  },
  navigationElements: {
    width: '30%',
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: "center",
  },
  navigationButtons: {
    width: '100%',
    maxWidth: '1100px',
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "5px"
  },
  navigationElement: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "flex-start",
  },
  SelectStyle:{
    width: "100%",
    maxWidth: "300px",
    marginRight: "20px",
  },
  SelectStyleMin:{
    width: "100%",
    maxWidth: "140px",
    marginRight: "20px",
  },
  addIlements:{
    border: "1px solid #e91e62",
    borderRadius: "4px",
    width: "100%",
    margin: "20px 20px 0 0",
    background: "#f1e0e0",
    display:"flex",
    flexWrap: "wrap",
    maxHeight: "250px",
    overflow: "scroll",
    '& > img': {
      width: "23%",
      height: "80px",
      objectFit: "contain",
    }
  },
  stageElements:{
    backgroundSize: "cover!important"
  }
};

export default ConstructorPage;
