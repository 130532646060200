import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoAreaNumber.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.bgSectionBooking} style={{backgroundImage: "url(/static/media/bg2.84378183.jpg)"}}>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.titleWhile}>КАК ОФОРМИТЬ ЗАКАЗ</h2>
              <h5 className={classes.descriptionWhile}>
                Проходит 4 этапа от момента заказа до получения долгожданной посылки.
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer>
              <GridItem className={classes.border} xs={12} sm={12} md={3}>
                <InfoArea
                  title="Заказ"
                  description="
                  Свяжитесь с нами удобным для вас способом: онлайн или по телефону"
                  number="1"
                />
              </GridItem>
              <GridItem className={classes.border} xs={12} sm={12} md={3}>
                <InfoArea
                  title="Согласование"
                  description="
                    Информативная поддержка клиента до полного изготовления продукции                   "
                  number="2"
                />
              </GridItem>
              <GridItem className={classes.border} xs={12} sm={12} md={3}>
                <InfoArea
                  title="Оплата"
                  description="
                    С помощью Системы (ЕРИП) или
                    Наличными (курьеру)              "
                  number="3"
                />
              </GridItem>
              <GridItem className={classes.border} xs={12} sm={12} md={3}>
                <InfoArea
                  title="Получение заказа"
                  description="
                    В течении 1-5 дней с момента полного согласования."
                  number="4"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
