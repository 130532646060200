import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/IMG_1968.jpg";
import team2 from "assets/img/faces/IMG_1984.jpg";
import team3 from "assets/img/faces/img_2051.jpg";
import team4 from "assets/img/faces/кружки.jpg";
import team5 from "assets/img/faces/холст(2).jpg";
import team6 from "assets/img/faces/магнит(2).jpg";


const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <div className={classes.box}>
        <h2 className={classes.title}>Фотоателье <span  className={classes.titleSpan}>«A-PRIORI»</span> - выпускные альбомы, детский фотограф,
          свадебный фотограф, семейная фотосъемка.</h2>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <div className={classes.boxBG} style={{backgroundImage:  `url('${team1}')`}}></div>
              </GridItem>
              <h4 className={classes.cardTitle}>
                Выпускные альбомы для средней школы
                <br />
              </h4>
              <Link to={"/high-school"} className={classes.link}>
                <Button color="rose" round className={classes.navButton}>
                  смотреть
                </Button>
              </Link>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>

            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <div className={classes.boxBG} style={{backgroundImage:  `url('${team2}')`}}></div>
              </GridItem>
              <h4 className={classes.cardTitle}>
                Выпускные альбомы для начальной школы
                <br />
              </h4>
              <Link to={"/jonior-school"} className={classes.link}>
                <Button color="rose" round className={classes.navButton}>
                  смотреть
                </Button>
              </Link>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>

            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <div className={classes.boxBG} style={{backgroundImage:  `url('${team3}')`}}></div>
              </GridItem>
              <h4 className={classes.cardTitle}>
                Выпускные альбомы для детского сада
                <br />
              </h4>
              <Link to={"/kindergarten"} className={classes.link}>
                <Button color="rose" round className={classes.navButton}>
                  смотреть
                </Button>
              </Link>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <div className={classes.boxBG} style={{backgroundImage:  `url('${team5}')`}}></div>
              </GridItem>
              <h4 className={classes.cardTitle}>
                Холсты
                <br />
              </h4>
              <Link to={"/canvas"} className={classes.link}>
                <Button color="rose" round className={classes.navButton}>
                  заказать холст
                </Button>
              </Link>
            </Card>
          </GridItem>
          {/*<GridItem xs={12} sm={12} md={4}>*/}
          
          {/*  <Card plain>*/}
          {/*    <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>*/}
          {/*      <div className={classes.boxBG} style={{backgroundImage:  `url('${team4}')`}}></div>*/}
          {/*    </GridItem>*/}
          {/*    <h4 className={classes.cardTitle}>*/}
          {/*      Печать на кружках*/}
          {/*      <br />*/}
          {/*    </h4>*/}
          {/*    <Link to={"/cup"} className={classes.link}>*/}
          {/*      <Button color="rose" round className={classes.navButton}>*/}
          {/*        выбрать рисунок*/}
          {/*      </Button>*/}
          {/*    </Link>*/}
          {/*  </Card>*/}
          {/*</GridItem>*/}
          {/*<GridItem xs={12} sm={12} md={4}>*/}
          
          {/*  <Card plain>*/}
          {/*    <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>*/}
          {/*      <div className={classes.boxBG} style={{backgroundImage:  `url('${team6}')`}}></div>*/}
          {/*    </GridItem>*/}
          {/*    <h4 className={classes.cardTitle}>*/}
          {/*      Магниты*/}
          {/*      <br />*/}
          {/*    </h4>*/}
          {/*    <Link to={"/magnet"} className={classes.link}>*/}
          {/*      <Button color="rose" round className={classes.navButton}>*/}
          {/*        выбрать магнит*/}
          {/*      </Button>*/}
          {/*    </Link>*/}
          {/*  </Card>*/}
          {/*</GridItem>*/}
        </GridContainer>
      </div>
    </div>
  );
}
