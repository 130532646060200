import React, {useState} from "react";

import { postOrder } from "../../../actions/orders"

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import PanoramaVerticalIcon from '@material-ui/icons/PanoramaVertical';
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import Tooltip from "@material-ui/core/Tooltip";


import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/canvasStyle.js";
import {connect} from "react-redux";
import PropTypes from "prop-types";


import SectionCropper from "./SectionCropper";


const useStyles = makeStyles(styles);

const WorkSection = ({ isAuthenticated, auth, sendOrder, history }) => {
  const classes = useStyles();
  
  const sizeMap = [
    {type:"small", aspect: 100 / 150, price:6, label: "10х15"},
    {type:"big", aspect: 150 / 200, price:13, label: "15х20"},
    {type:"smallHor", aspect: 150 / 100, price:6, label: "15x10"},
    {type:"bigHor", aspect: 200 / 150, price:13, label: "20х15"}
  ];
  const laminationMap = [
    {type:"matt", label: "Матовая"},
    {type:"glossy",  label: "Глянцевая"},
  ];
  
  const [sizeCanvas, setSizeCanvas] = useState("big");
  const [laminationCanvas, setLaminationCanvas] = useState("matt");
  //const [imageCanvas, setImageCanvas] = useState(null);
  const [imageCanvas, setImageCanvas] = useState("some_path_here.jpeg");//TODO remove it
  const [errorCanvas, setErrorCanvas] = useState(null);
  
  const onSubmit = async e => {
    e.preventDefault();
    if (imageCanvas){
      setErrorCanvas(null);
      sendOrder(auth._id, "Холст", "A4", "1", "some_path_here.jpeg", "Оформлен", "25", auth.phone)
        .then(()=>{
          history.push('/profile');
        })
        .catch((err)=>{
          setErrorCanvas("Что-то пошло не так(");
        });
    } else{
      setErrorCanvas("Загрузите изображение");
    }
  };
  
  return (
    <div className={classes.container}>
    <div className={classes.section}>
      <GridContainer justify="space-between">
        <GridItem cs={12} sm={12} md={6}>
          <SectionCropper aspectSize={sizeMap.find(size => size.type === sizeCanvas).aspect}/>
        </GridItem>
        <GridItem cs={12} sm={12} md={6}>
          <h2 className={classes.title}>Печать магнита</h2>
          <div className={classes.price}>
            {sizeMap.find(size => size.type === sizeCanvas).price} руб</div>
          <h3 className={classes.description}>Описание</h3>
          <p className={classes.description}>
            Печать на магнитном листе с <br/> ламинацией Плотная бумага 180-200 <br/>
            Лаковое покрытие,выполняющее защитную и декоративную функции<br/>
            Форматы: {sizeMap[0].label}, {sizeMap[1].label} - вертикальный; {sizeMap[2].label}, {sizeMap[3].label} - горизонтальный.<br/>
          </p>
          <form>
            <GridContainer>
             
              <GridItem>
               <h4>Выберите размер</h4>
              </GridItem>
              <GridItem>
              <GridContainer>
                <GridItem md={5}>
                  <h4><PanoramaHorizontalIcon fontSize="large" color="secondary"/>горизонтальный</h4>
                </GridItem>
                <GridItem md={5}>
                  <h4><PanoramaVerticalIcon fontSize="large" color="secondary"/> вертикальный</h4>
                </GridItem>
              </GridContainer>
              </GridItem>
              <GridItem >
                {
                  sizeMap.map((size)=>{
                    return (<FormControlLabel
                      control={
                        <Radio
                          checked={sizeCanvas === size.type}
                          onChange={() => setSizeCanvas(size.type)}
                          value={size.aspect}
                          name="size"
                          icon={
                            <Check className={classes.uncheckedIcon} />
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label={size.label}
                    />)
                  })
                }
              </GridItem>
              <br/>
              <GridItem>
                <h4>Ламинация</h4>
              </GridItem>
              <GridItem >
                {
                  laminationMap.map((size)=>{
                    return (
                      <FormControlLabel
                        control={
                          <Radio
                            checked={laminationCanvas === size.type}
                            onChange={() => setLaminationCanvas(size.type)}
                            value={size.aspect}
                            name="size"
                            icon={
                              <Check className={classes.uncheckedIcon} />
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label={size.label}
                    />
                    )
                  })
                }
              </GridItem>
              <br/>
              <GridItem>
                <br/><br/>
                  <Tooltip
                    id="tooltip-left"
                    title={ isAuthenticated ? "" : "Пожалуйста войдите в профиль"} 
                    placement="bottom"
                  >
                      <Button 
                        color={ isAuthenticated ? "rose" : ""}
                        size="lg"
                        onClick={(e)=> onSubmit(e)}
                      >Заказать печать</Button>
                  </Tooltip>
              </GridItem>
              <GridItem>
                <h4 className={classes.error}>{errorCanvas}</h4>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>,
      </GridContainer>
    </div>
    </div>
  );
}
WorkSection.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  sendOrder: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  auth: state.Auth
});


export default connect(mapStateToProps,{sendOrder:postOrder})(WorkSection)


