import React from "react";
import Carousel from "react-slick";
import {makeStyles} from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import { Link} from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';

import image1 from "assets/img/2.png";
import image2 from "assets/img/1.png";
import logo from "assets/img/logo.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
          <Card carousel>
            <Carousel {...settings}>
              
              <div className={classes.darkBg}>
                <img
                  src={image2}
                  alt="Second slide"
                  className="slick-image"
                />
                  <div  className={classes.whiteBg + " slick-caption"}>
                    <h2 className={classes.h2}>
                      Создайте Свой Фотоальбом
                    </h2>
                    <p className={classes.p}><CheckIcon fontSize="small" color="secondary"/> Выбери готовый дизайн</p>
                    <p className={classes.p}><CheckIcon fontSize="small" color="secondary"/> Загрузи свои фото</p>
                    <p className={classes.p}><CheckIcon fontSize="small" color="secondary"/> Размести их в шаблоне на свой вкус</p>
                    <p className={classes.p}><CheckIcon fontSize="small" color="secondary"/> Оформи заказ</p>
                    <Link to={"/constructor"} className={classes.link}>
                      <Button color="rose" size="lg">
                        Создать фотоальбом
                      </Button>
                    </Link>
                    <img
                      src={logo}
                      className={classes.logo}
                    />
                  </div>
              </div>
              <div className={classes.darkBg}>
                <img src={image1} alt="First slide" className="slick-image"/>
                <div  className={classes.whiteBg + " slick-caption"}>
                    <h2 className={classes.h2}>
                      Фотосессия которую вы заслужили
                    </h2>
                    <p className={classes.p}><CheckIcon fontSize="small" color="secondary"/>Посмотрите на примеры наших работ</p>
                    <Link to={"/photographer"} className={classes.link}>
                      <Button color="rose" size="lg">
                        смотреть примеры
                      </Button>
                    </Link>
                  <img
                    src={logo}
                    className={classes.logo}
                  />
                  </div>
                </div>
            </Carousel>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
