import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/components/politika.js";

const useStyles = makeStyles(styles);

export default function Politika(props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.politika}>
          <h1>Политика конфиденциальности интернет-магазина a-priori.by</h1>
          <div className="news-text">
            <p>Администрация Сайта осуществляет сбор, хранение, обработку и использование информации о Пользователях в
              целях выполнения обязательств Администрации Сайта перед Пользователями в отношении использования Сайта и
              его сервисов.</p>

            <p>Состав подлежащей сбору, хранению, обработке и использованию информации:</p>

            <p>1. Личные данные Пользователей:</p>

            <p>1.1. обязательные: предоставляемые Пользователями минимально необходимые для регистрации учетной записи
              на Сайте (фамилия имя, номер мобильного телефона и адрес электронной почты);</p>

            <p>1.2. дополнительные:</p>

            <p>а) указанные Пользователями по их собственной инициативе в профиле учетной записи:</p>

            <p>– фамилия, имя, отчество; дата рождения; город (населенный пункт); используемая техника; род занятий;
              интересы; подпись на форуме; социальные сети; сайт; реквизиты банковских карт, используемых Пользователем
              для пополнения баланса учетной записи для последующей оплаты предлагаемых Сайтом услуг (обработка платежей
              по&nbsp;банковским картам и&nbsp;их&nbsp;хранение осуществляется через систему электронных платежей&nbsp;
              bePaid, которая отвечает всем требованиям безопасности
              передачи данных – PCI DSS Level 1);</p>

            <p>–&nbsp; в период пользования услугами компании в открытом доступе на &nbsp;составных частях и сервисах
              Сайта (фамилия, имя, отчество, дата и место рождения, данные о гражданстве, домашний адрес, телефон,
              семейное положение, информация об образовании и роде занятий, родственные связи, а также сведения и
              документы, их реквизиты, подтверждающие персональные данные физических лиц).</p>

            <p>б) предоставляемые Пользователями по запросу Администрации Сайта в целях исполнения обязательств,
              вытекающих из Правил пользования интернет-ресурсом и публичного Договора на оказание услуг. Администрация
              Сайта вправе, в частности, запросить у Пользователя фотографию (копию) оригинального документа,
              удостоверяющего личность, либо иного документа, содержащего имя, фамилию, отчество, фотографию
              Пользователя, а также иную дополнительную информацию, которая по усмотрению Администрации Сайта будет
              являться необходимой и достаточной для идентификации такого Пользователя и позволит исключить
              злоупотребления и нарушения прав третьих лиц.</p>

            <p>2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта:</p>

            <p>– данные, получаемые при доступе к Сайту, включающие в себя сведения о технических средствах
              (устройствах), технологическом взаимодействии с Сайтом (в т. ч. IP-адрес, файлы cookie);</p>

            <p>– любые текстовые сообщения, фотографические и&nbsp;мультимедийные файлы, размещаемые и загружаемые
              Пользователями по их собственной инициативе на составных частях Сайта и его сервисах.</p>

            <p>Сбор, хранение, обработка и использование личных данных и иной информации Пользователей осуществляется
              Администрацией Сайта на протяжении всего времени пользования услугами Сайта с момента регистрации учетной
              записи Пользователя.</p>

            <p>Вся информация Пользователей хранится исключительно на электронных носителях и обрабатывается с
              использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка
              таких данных необходима в связи с исполнением требований законодательства.</p>

            <p>Личные данные и иная информация Пользователей не передаются&nbsp; третьим лицам без их согласия, за
              исключением случаев, предусмотренных законодательством, и сведений, размещенных Пользователями по их
              собственной инициативе в открытом доступе на Сайте и его сервисах.</p>

            <p>Предоставление личных данных и иной информации Пользователей&nbsp; осуществляется по запросу
              государственных органов в порядке компетенции, предусмотренной законодательством.</p>

            <p>В целях исполнения соглашения между Пользователем и Администрацией Сайта и предоставления Пользователю
              доступа к использованию функционала Сайта, Администрация Сайта развивает предоставляемые сервисы и
              продукты, разрабатывает и внедряет новые сервисы и продукты, оптимизирует качество сервисов и продуктов,
              совершенствует доступный функционал Сайта и сервисов. Для обеспечения реализации указанных целей
              Пользователь соглашается на осуществление Администрацией Сайта с соблюдением применимого законодательства
              сбора, хранения, накопления, систематизации, извлечения, сопоставления, использования, наполнения
              (уточнения) их данных, а также на получение и передачу аффилированным лицам и партнерам результатов
              автоматизированной обработки таких данных с применением различных моделей оценки информации в виде
              целочисленных и/или текстовых значений и идентификаторов, соответствующих заданным в запросах оценочным
              критериям, для обработки данных Администрацией Сайта и/или лицами, указанными в настоящем пункте.</p>

            <p>Личные данные Пользователей подлежат обезличиванию по достижении целей сбора, обработки, распространения,
              предоставления или в случае утраты необходимости в достижении этих целей, если иной срок их хранения не
              предусмотрен законодательными актами.</p>

            <p>Размещая на Сайте и его сервисах по собственной инициативе в открытом виде личные данные и другую
              информацию, Пользователи осознают, что указанная информация становится доступной третьим лицам с учетом
              особенностей архитектуры и функционала Сайта, и соглашаются с этим. В таком случае Администрация Сайта
              ответственность за действия третьих лиц не несет. Администрация Сайта рекомендует Пользователям
              ответственно подходить к решению вопроса об объеме размещаемой личной информации о себе на Сайте.</p>

            <p>Администрация Сайта принимает технические и организационно-правовые меры в целях обеспечения защиты
              личных данных и иной информации Пользователей от неправомерного или случайного доступа к ним, уничтожения,
              изменения, блокирования, копирования, распространения, а также от иных неправомерных действий. Исключение
              составляют сведения, самостоятельно размещенные Пользователями в открытом доступе на Сайте и его сервисах.
              В случае копирования и распространения такой информации третьими лицами Администрация Сайта
              ответственности не несет.</p>

            <p>Для авторизации доступа к Сайту используется персональный логин и пароль Пользователя. Указанная
              информация является конфиденциальной. Ответственность за ее сохранность несет Пользователь. Пользователь
              не вправе передавать персональный логин и пароль третьим лицам.</p>

            <p>В целях обеспечения более надежной защиты информации Администрация Сайта использует систему привязки
              учетной записи (аккаунта) Пользователя к номеру мобильного телефона и адресу электронной почты.
              Электронная почта в дальнейшем может использоваться в качестве логина для входа в аккаунт</p>
            

            <p>Пользователи вправе обращаться к Администрации Сайта по вопросам пользования Сайтом и его ресурсами.</p>

            <p>Почтовый адрес, юридический адрес, e-mail, телефон: 212011 г.Могилёв ул.Серова 29, г.Могилёв ул.Серова 29, a.priori.pint@gmail.com, +375255020854. Фотоателье "Априори".</p>

            <p>&nbsp;</p></div>
        </div>
      </div>
    </div>
  );
}
