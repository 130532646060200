import  axios from 'axios';
import { setAlert } from "./alert";
import { upload } from 'ya-disk';
import { POST_IMAGE, POST_IMAGE_ERROR } from "../actions/types";

export const postImage = ( blob, folder, fileName ) => async dispatch => {

    const API_TOKEN = 'AgAAAABFgmTBAAaV45UoeaVZR0bHitIiyrUOFw8';
    
    const path = 'disk:/'+folder+'/'+fileName;
        
    
    const { href, method } = await upload.link(API_TOKEN, path, true);


    try {
        delete axios.defaults.headers.common["x-auth-token"];
        const res = await axios.put(href, blob);
        
        dispatch({
            type: POST_IMAGE,
            payload: res.data
        });
        
        dispatch(setAlert('Изображение загружено!', 'success'));

    } catch (err) {
        const errors = err.response.data.errors;

        if (errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: POST_IMAGE_ERROR
        });
    }
};

