import { container } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0",
    fontWeight: "500",
    fontSize: "26px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    "&:hover,&:focus": {
      color: "#e91e63"
    }
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
    flexDirection: "column",
  },
  a: {
    color: "transparent",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: "#e91e63"
    }
  },
  p: {
    fontSize: "10px",
    margin: '0',
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: "#e91e63"
    }
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container:{
    ...container,
    display: "flex",
    justifyContent: "space-between",
alignItems: "center"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    textAlign: 'left',
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  containerFull: {
    ...container,
    textAlign: "left",
  },
  politika: {
    fontSize: "11px",
    // color: '#3c4858',
    color: "inherit",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: "#e91e63"
    }
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navLink:{
    padding:"5px"
  }
};
export default footerStyle;
