import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

import BeforeAfterSlider from 'react-before-after-slider'

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ImageGallery from 'react-image-gallery';

import HeaderLinks from "../../components/Header/HeaderLinks.js";

import examples1 from "assets/img/examples/IMG_6145.png";
import examples2 from "assets/img/examples/IMG2.png";
import examples5 from "assets/img/examples/IMG_9767.png";
import examples6 from "assets/img/examples/IMG_9767N.png";
import logo from "assets/img/logo.png";

import styles from "../../assets/jss/material-kit-react/views/school.js";


const useStyles = makeStyles(styles);


export default function Components(props) {
  const classes = useStyles();
  const {...rest} = props;

  const images = [
    {
      original: 'https://www.achernishev.ru/images/content/Kuskovo/kuskovo-06.jpg',
      thumbnail: 'https://www.achernishev.ru/images/content/Kuskovo/kuskovo-06.jpg',
    },
    {
      original: 'https://d2oeuiete2jos.cloudfront.net/wp-content/uploads/2015/04/10-svadebnaya-fotosessiya-na-plyazhe.jpg',
      thumbnail: 'https://d2oeuiete2jos.cloudfront.net/wp-content/uploads/2015/04/10-svadebnaya-fotosessiya-na-plyazhe.jpg',
    },
    {
      original: 'https://images.by.prom.st/14400115_svadebnaya-fotosemka-svadebnyj.jpg',
      thumbnail: 'https://images.by.prom.st/14400115_svadebnaya-fotosemka-svadebnyj.jpg',
    },
  ];

  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks/>}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />

      <Parallax
        filter
        image={
          "https://pechat-pro.ru/wp-content/cache/thumb/7d/d6dbaee6aaa277d_830x0.jpg"
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.textParallax}>Услуги дизайнера </h1>
              <h3 className={classes.textParallax}>В своей работе мы используем индивидуальный подход</h3>
            </GridItem>
          </GridContainer>
        </div>
        <img
          src={logo}
          className={classes.logo}
        />
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.boxWhite}>
              <GridContainer>
                <GridItem xs={10} sm={10} md={6}>
                  <BeforeAfterSlider
                    before={examples1}
                    after={examples2}
                    width={600}
                    height={580}
                  />
                </GridItem>
                <GridItem xs={10} sm={10} md={4}>
                  <h2 className={classes.bold}>Ретушь фото</h2>
                  <p>Ретушь - обработка фотографии для улучшения их качества.Выравнивание текстур</p>
                  <h3>
                    Mожно заказать от 2р за фото
                  </h3>
                  <h2 className={classes.bold}>Техническая ретушь фото</h2>
                  <p>Техническая ретушь - восстановление повреждённых печатных снимков</p>
                  <h3>
                    Mожно заказать от 5р за фото
                  </h3>
                  
                </GridItem>
              </GridContainer>
        </div>
          <div className={classes.boxGrey}>
            <GridContainer>
              <GridItem xs={10} sm={10} md={6}>
                <BeforeAfterSlider
                  before={examples5}
                  after={examples6}
                  width={600}
                  height={500}
                />
              </GridItem>
              <GridItem xs={10} sm={10} md={4}>
                <h2 className={classes.bold}>Художественная ретушь фото</h2>
                <p>Художественная ретушь - улучшение качества снимка и доработка изображения (замена фона,коррекция внешности)</p>
                <h3>
                  Mожно заказать от 3р за фото
                </h3>
                <h2 className={classes.bold}>Цветокоррекция фото</h2>
                <p>Цветокоррекция - изменение оттенка,насыщенности и т.д. Необходима при неточной передаче цветов на изображении.</p>
                <h3>
                  Mожно заказать от 1.5р за фото
                </h3>
                  </GridItem>
            </GridContainer>
            
            
          
        </div>
        <div className={classes.boxWhite}>
          <h2 className={classes.bold}>Примеры нашей фотосъемки</h2>
          <GridContainer><GridItem xs={10} sm={10} md={10}><ImageGallery thumbnailPosition='right'
                                                                         items={images}/></GridItem></GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
