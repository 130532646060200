import { container, title } from "assets/jss/material-kit-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

const profilePageStyle = {
  container,
  ...tooltipsStyle,
  ...customCheckboxRadioSwitch ,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  bgGrey:{
    background: "#eee",
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    fontSize: "2.25rem",
    fontWeight: "600",
    display: "block",
    textAlign: "left",
    position: "relative"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0"
  },
  price:{
    color:'#E91E63',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'italic'
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  ...modalStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  // title: {
  //   ...title,
  //   marginBottom: "1rem",
  //   marginTop: "0",
  //   minHeight: "32px",
  //   textDecoration: "none",
  //   textAlign: "center !important",
  //   paddingTop: "10px",
  // },
  description: {
    margin: "4px 0",
    maxWidth: "800px",
    color: "#3c4858",
    // textAlign: "center !important",
    paddingBottom:"0",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  centerText: {
    textAlign: "center",
    margin: "0",
    fontSize: "14px",
  },
  centerPrice: {
    textAlign: "center",
    margin: "0 0 25px",
  },
  tableHeader: {
    backgroundColor: "rgba(233, 30, 98,0.5)"
  },
  select: {
    color: '#f54134',
    fontSize: '14px',
    minWidth: '140px',
  },
  red: {
    color: '#f54134',
    fontWeight: '500'
  },
  green: {
    color: '#09a924',
    fontWeight: '500'
  },
  buttonPay:{
    width: "200px !important"
  },
};

export default profilePageStyle;
