import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PinDropIcon from '@material-ui/icons/PinDrop';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CommuteIcon from '@material-ui/icons/Commute';
import PaymentIcon from '@material-ui/icons/Payment';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import {Formik, Form} from 'formik';
import * as Yup from 'yup';

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import {setAlert} from "../../../actions/alert";
import { send } from "../../../actions/auth";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Alert from "../../../components/Snackbar/Alert";

import Raschet1 from "assets/img/Raschet-Logo.svg";
import Raschet2 from "assets/img/Webpay-Logo.svg";


const useStyles = makeStyles(styles);

const WorkSection = ( { send } )  => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <h2 className={classes.title}>ОТПРАВЬТЕ НАМ СООБЩЕНИЕ</h2>
        <GridContainer justify="space-between">
          <GridItem cs={12} sm={12} md={6}>

            <h4 className={classes.description}>
              У Вас остались вопросы ?
              <br/> Напишите нам и мы с радостью Вам ответим

            </h4>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                text: ''
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required('Введите Имя'),
                email: Yup.string()
                  .email('Введите правильный email')
                  .required('Введите email'),
                phone: Yup.number()
                  .required('Введите номер телефона'),
                text: Yup.string()
                  .required('Введите текст сообщения'),
              })}
              onSubmit={fields => send(fields)}
              render={({errors, status, touched, values, handleChange}) => (
                <Form>
                  <GridContainer>
                    <GridItem>
                      <CustomInput
                        labelText="Ваше имя"
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                          onChange: handleChange,
                          error: errors.name && touched.name
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                          onChange: handleChange,
                          error: errors.email && touched.email
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <CustomInput
                        labelText="Номер телефона"
                        id="tel"
                        formControlProps={{
                          fullWidth: true,
                          onChange: handleChange,
                          error: errors.phone && touched.phone
                        }}
                        inputProps={{
                          type: "tell",
                          name: "phone"
                        }}
                      />
                    </GridItem>
                    <CustomInput
                      labelText="Введите текст сообщения"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea,
                        onChange: handleChange,
                        error: errors.text && touched.text
                      }}
                      inputProps={{
                        type: "text",
                        name: "text",
                        multiline: true,
                        rows: 5
                      }}
                    />
                    <GridItem xs={12} sm={12} md={4}>
                      <Button color="rose" size="lg" type="submit">ОТПРАВЬТЕ СООБЩЕНИЕ</Button>
                    </GridItem>
                    <Alert/>
                  </GridContainer>
                </Form>
              )}
            />
          </GridItem>
          <GridItem cs={12} sm={12} md={4}>
            <GridContainer>
              <GridItem>
                <GridContainer>
                  <GridItem xs={2} sm={2} md={2}><PinDropIcon fontSize="large" color="secondary"/></GridItem>
                  <GridItem xs={10} sm={10} md={10}>
                    <h4 className={classes.h4}>Наш офис находится</h4>
                    <p>Могилев ул.Серова д29</p>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer>
                  <GridItem xs={2} sm={2} md={2}><PhoneIcon fontSize="large" color="secondary"/></GridItem>
                  <GridItem xs={10} sm={10} md={10}>
                    <h4 className={classes.h4}>Контактные телефоны</h4>
                    <p><a href="tel:+375 (29) 847-98-34 ">+375 (29) 847-98-34 </a></p>
                    <p><a href="tel:+375 (25) 502-08-54 ">+375 (25) 502-08-54 </a></p>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer>
                  <GridItem xs={2} sm={2} md={2}><BusinessCenterIcon fontSize="large" color="secondary"/></GridItem>
                  <GridItem xs={10} sm={10} md={10}>
                    <h4 className={classes.h4}>Информация о нас</h4>
                    <p>Мы работем ежедневно с 8 00 до 20 00 </p>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer>
                  <GridItem xs={2} sm={2} md={2}><PaymentIcon fontSize="large" color="secondary"/></GridItem>
                  <GridItem xs={10} sm={10} md={10}>
                    <h4 className={classes.h4}>Способы оплаты</h4>
                    <GridContainer>
                      <GridItem xs={5} sm={5} md={5}>
                        <img
                        alt="Система Расчета"
                        src={Raschet1}
                        className={classes.img}
                        /></GridItem>
                      <GridItem  xs={5} sm={5} md={5}>
                        <img
                          alt="Платёжная система webpay"
                          src={Raschet2}
                          className={classes.img}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer>
                  <GridItem xs={2} sm={2} md={2}><CommuteIcon fontSize="large" color="secondary"/></GridItem>
                  <GridItem xs={10} sm={10} md={10}>
                    <h4 className={classes.h4}>Способы доставки</h4>
                    <p>
                      Курьером по г.Минску,г.Могилёву (бесплатно).<br/>
                      Почтой, за счет покупателя(пересылка бесплатно при заказе от 100 руб)
                    </p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

WorkSection.propTypes = {
  send: PropTypes.func.isRequired,
};

export  default connect(null, { setAlert, send })(WorkSection)
