import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import HeaderLinks from "../../components/Header/HeaderLinks.js";
// import HeaderLeftLinks from "../../components/Header/HeaderLeftLinks.js";
import SectionOurWork from "./Sections/SectionOurWork.js";
import SectionAboutUs from "./Sections/SectionAboutUs.js";
import SectionQuestion from "./Sections/SectionQuestion.js";
import SectionBooking from "./Sections/SectionBooking.js";

import SectionCarousel from "./Sections/SectionCarousel.js";
import TeamSection from "./Sections/TeamSection.js";



// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
// import { Link } from "react-router-dom";
//
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        // leftLinks={ <HeaderLeftLinks /> }
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      
      <SectionCarousel />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <TeamSection />
        <SectionOurWork />
        <SectionAboutUs />
        <SectionBooking />
        <SectionQuestion />
        


        {/*<SectionBasics />*/}
        {/*<SectionNavbars />*/}
        {/*<SectionTabs />*/}
        {/*<SectionPills />*/}
        {/*<SectionNotifications />*/}
        {/*<SectionTypography />*/}
        {/*<SectionJavascript />*/}
        {/*<SectionCarousel />*/}
        {/*<SectionCompletedExamples />*/}
        {/*<SectionLogin />*/}
        {/*<GridItem md={12} className={classes.textCenter}>*/}
          {/*<Link to={"/login-page"} className={classes.link}>*/}
            {/*<Button color="primary" size="lg" simple>*/}
              {/*View Login Page*/}
            {/*</Button>*/}
          {/*</Link>*/}
        {/*</GridItem>*/}
        {/*<SectionExamples />*/}
        {/*<SectionDownload />*/}
      </div>
      <Footer />
    </div>
  );
}
