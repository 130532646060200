import { combineReducers } from 'redux';
import AlertReducer from './alert';
import AuthReducer from './Auth'
import OrdersReducer from './orders'

export default combineReducers({
  alert: AlertReducer, 
  Auth: AuthReducer, 
  Orders: OrdersReducer
});
 
