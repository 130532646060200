/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {List, ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import DashboardIcon from '@material-ui/icons/Dashboard';
import PanoramaIcon from '@material-ui/icons/Panorama';

import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const {whiteFont} = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>

              <Link to={"/"} className={classes.link}>
                <span
                  className={classes.block}
                >
                  A-PRIORI
                </span>
              </Link>
            </ListItem>
          </List>
        </div>
        <div>
          <List className={classes.list}>
            {/*<ListItem className={classes.inlineBlock}>*/}
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button*/}
                  {/*color="transparent"*/}
                  {/*round*/}
                  {/*size="sm"*/}
                  {/*className={classes.navLink}*/}
                {/*>*/}
                  {/*<i className={classes.socials + " fab fa-facebook"} />*/}
                {/*</Button>*/}
              {/*</Link>*/}
            {/*</ListItem>*/}
            {/*<ListItem className={classes.inlineBlock}>*/}
              {/*<Link to={"/"} className={classes.link}>*/}
                {/*<Button*/}
                  {/*color="transparent"*/}
                  {/*round*/}
                  {/*size="sm"*/}
                  {/*className={classes.navLink}*/}
                {/*>*/}
                  {/*<i className={classes.socials + " fab fa-vk"} />*/}
                {/*</Button>*/}
              {/*</Link>*/}
            {/*</ListItem>*/}
          </List>
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <List className={classes.list}>
            <p className={classes.p}>Фотоателье A-PRIORI это знак истинного качества </p>
          </List>
        </div>
        <div>
          <List className={classes.list}>
            <Link target="_blank" to={"/politika"} className={classes.politika}>
              Политика конфиденциальности
            </Link>
          </List>
        </div>
      </div>
      <div className={classes.containerFull}>
        <p  className={classes.p}>
          © 2020 Права защищены официальная информация, Государственная регистрация №  791233325
          от 06.04 2020 г .Администрация Ленинского района г.Могилёва. Регистрации интернет-магазина в Торговом реестре №  484205 от 11.06.2020г.
          Почтовый адрес, юридический адрес, e-mail, телефон:  212011 г.Могилёв ул.Серова 29, г.Могилёв ул.Серова 29, a.priori.pint@gmail.com,
          +375255020854. Фотоателье "Априори".
          Директор Сотниченко Юлия Александровна УНП 791233325. Режим работы с 9:00 до 19:00,
          a-priori.by
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
