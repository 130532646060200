import {container, title } from "assets/jss/material-kit-react.js";

const workStyle = {
  container: {
    zIndex: "12",
    position: "relative",
    ...container
  },
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "10px",
    marginTop: "0",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "left"
  },
  description: {
    color: "#999",
    textAlign: "left"
  },
  textCenter: {
    textAlign: "left"
  },
  h4: {
    fontWeight: "bold",
    marginTop: "5px",
    marginBottom: "5px",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  img: {
    height: "48px"
  }
};

export default workStyle;
