import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';

import HeaderLinks from "../../components/Header/HeaderLinks.js";


import styles from "../../assets/jss/material-kit-react/views/school.js";


import imageBG from "assets/img/school/junior/bg2.jpg";
import image1 from "assets/img/school/junior/IMG_1978.jpg";
import image2 from "assets/img/school/junior/IMG_1979.jpg";
import image3 from "assets/img/school/junior/IMG_1981.jpg";
import image5 from "assets/img/school/junior/IMG_1983.jpg";

import image6 from "assets/img/school/junior/IMG_2001.jpg";
import image7 from "assets/img/school/junior/IMG_2000.jpg";
import image8 from "assets/img/school/junior/IMG_1999.jpg";
import image9 from "assets/img/school/junior/IMG_1994.jpg";

import image10 from "assets/img/school/junior/IMG_2049.jpg";
import image11 from "assets/img/school/junior/IMG_2047.jpg";
import image12 from "assets/img/school/junior/IMG_2045.jpg";
import image13 from "assets/img/school/junior/IMG_2043.jpg";
import image14 from "assets/img/school/junior/IMG_2042.jpg";

import image15 from "assets/img/school/junior/IMG_2004.jpg";
import image16 from "assets/img/school/junior/IMG_2003.jpg";
import image17 from "assets/img/school/junior/IMG_2002.jpg";
import image18 from "assets/img/school/junior/IMG_1993.jpg";
import image19 from "assets/img/school/junior/IMG_1989.jpg";

import image20 from "assets/img/school/junior/IMG_2039.jpg";
import image21 from "assets/img/school/junior/IMG_2037.jpg";
import logo from "assets/img/logo.png";




const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const images = [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image5,
      thumbnail: image5,
    },
  ];

  const images2 = [
    {
      original: image6,
      thumbnail: image6,
    },
    {
      original: image7,
      thumbnail: image7,
    },
    {
      original: image8,
      thumbnail: image8,
    },
    {
      original: image9,
      thumbnail: image9,
    },
  ];
  const images3 = [
    {
      original: image10,
      thumbnail: image10,
    },
    {
      original: image11,
      thumbnail: image11,
    },
    {
      original: image12,
      thumbnail: image12,
    },
    {
      original: image13,
      thumbnail: image13,
    },
    {
      original: image14,
      thumbnail: image14,
    }
  ];
  const images4 = [
    {
      original: image15,
      thumbnail: image15,
    },
    {
      original: image16,
      thumbnail: image16,
    },
    {
      original: image17,
      thumbnail: image17,
    },
    {
      original: image18,
      thumbnail: image18,
    },
    {
      original: image19,
      thumbnail: image19,
    },
  ];
  const images5 = [
    {
      original: image20,
      thumbnail: image20,
    },
    {
      original: image21,
      thumbnail: image21,
    }
  ];
  return (
    <div>
      <Header
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />

      <Parallax
        filter
        image={imageBG}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.textParallax}>Выпускные альбомы для начальной школы</h1>
            </GridItem>
          </GridContainer>
        </div>
        <img
          src={logo}
          className={classes.logo}
        />
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.boxGrey}>
          <div className={classes.container}>
            <h4 className={classes.textCenter}>Выпускной альбом - замечательная традиция сохранения теплых воспоминаний о своих
              друзьях и товарищах, с которыми вы провели не один год своей жизни. Фотоальбомы бережно хранят,
              показывают детям и внукам, передают из поколения в поколение.</h4>
          </div>
        </div>

        <div className={classes.boxWhite}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Выпускной альбом <br/> <span className={classes.red}>«Класический»</span></h2>
              <h4>Альбом с  двумя портетными съемками  и класическим оформлением.</h4>
              <h3 className={classes.red}>35 руб</h3>
              <h4>В стоимость выпускного альбома входит:</h4>
              <ul>
                <li>Портретная и групповая фотосъемка</li>
                <li>Обработка фотографий и верстка макета</li>
                <li>Согласование макета перед печатью</li>
                <li>Сопровождение макета менеджером</li>
                <li>Печать альбомов</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать альбом класический*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxGrey}>
          <GridContainer>

            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Выпускной альбом <br/> <span className={classes.red}>«Наш дружный класс»</span></h2>
              <h4>яркий стиль для маленьких и позитивных выпускников.</h4>
              <h3 className={classes.red}>30 руб</h3>
              <h4>В стоимость выпускного альбома входит:</h4>
              <ul>
                <li>Портретная и групповая фотосъемка</li>
                <li>Обработка фотографий и верстка макета</li>
                <li>Согласование макета перед печатью</li>
                <li>Сопровождение макета менеджером</li>
                <li>Печать альбомов</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать альбом дружный класс*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images2} />
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxWhite}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images3} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Выпускной альбом <br/> <span className={classes.red}>«Планшет 1 класс»</span></h2>
              <h4>теплые осенние цвета с яркими акцентами.</h4>
              <h3 className={classes.red}>30 руб</h3>
              <h4>В стоимость выпускного альбома входит:</h4>
              <ul>
                <li>Портретная и групповая фотосъемка</li>
                <li>Обработка фотографий и верстка макета</li>
                <li>Согласование макета перед печатью</li>
                <li>Сопровождение макета менеджером</li>
                <li>Печать альбомов</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать альбом планшет 1 класс*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxGrey}>
          <GridContainer>

            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Выпускной альбом  <br/> <span className={classes.red}>«Photostory»</span></h2>
              <h4>Яркий, динамичный альбом с фото  в полный рост.</h4>
              <h3 className={classes.red}>30 руб</h3>
              <h4>В стоимость выпускного альбома входит:</h4>
              <ul>
                <li>Портретная и групповая фотосъемка</li>
                <li>Обработка фотографий и верстка макета</li>
                <li>Согласование макета перед печатью</li>
                <li>Сопровождение макета менеджером</li>
                <li>Печать альбомов</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать альбом  Photostory*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images4} />
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxWhite}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ImageGallery items={images5} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.bold}>Виньетка <br/> <span className={classes.red}>«Класическая»</span></h2>
              <h4>общее фото класса на формате А4</h4>
              <h3 className={classes.red}>12 руб</h3>
              
              <ul>
                <li>1 лист</li>
                <li>Матовая ламинация</li>
              </ul>
              {/*<Link to={""} className={classes.link}>*/}
                {/*<Button color="rose" size="lg">*/}
                  {/*заказать виньетку класическую*/}
                {/*</Button>*/}
              {/*</Link>*/}
            </GridItem>
          </GridContainer>
        </div>

        <div className={classes.boxWhite}>
          <h2 className={classes.bold} style={{paddingBottom: "20px"}}>Что будем делать в процессе?</h2>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>1</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Проведём две фотосъёмки
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>2</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Отберём лучшие кадры
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>3</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Применим ретушь ко всем фото
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>4</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Согласуем с вами макет
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>5</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Изготовим фотоальбом (учителю в подарок)
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}><span className={classes.circle}>6</span></GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardTitle}>
                      Доставим вам продукцию
                    </h4>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.boxWhite}>
          <h2 className={classes.bold}>Примеры нашей фотосъемки</h2>
          <GridContainer><GridItem xs={10} sm={10} md={10}><ImageGallery thumbnailPosition='right' items={images} /></GridItem></GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
