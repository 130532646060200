import {container} from "assets/jss/material-kit-react.js";

const carouselStyle = {
  section: {
    padding: "0",
    overflow: "hidden"
  },
  container,

  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  slickAdd: {
    position: "relative",
    marginTop: "-20%",
  },
  darkBg: {
    position: "relative",
    "&:after": {
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      content: "''",
      display: "block",
      zIndex: "1",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.57)",
    }
  },
  whiteBg: {
    background: "rgb(255 255 255 / 0.8)",
    padding: '15px',
    borderRadius: "10px",
    color: "#000!important",
    maxWidth: "600px",
    bottom: '50%!important',
    transform: 'translateY(50%)',
    textAlign: "left!important"
  },
  h2: {
    fontWeight: "bold",
    lineHeight: "1.15em",
  },
  p: {
    marginBottom: "3px",
  },
  logo: {
    position: "absolute",
    right: "20px",
    bottom: "20px",
    width: "100px"
  },
};

export default carouselStyle;
