import React, { useEffect, useState } from "react";
import { logout } from '../../actions/auth'
import { userOrders } from "../../actions/orders"
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "../../components/Header/Header.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from "../../components/CustomButtons/Button";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';



import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TableOrder from "./TableOrder";

const useStyles = makeStyles(styles);

const ProfilePage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  
  // const {isAuthenticated, auth, history, logout} = props;

  // if (!isAuthenticated){
  //   history.push('/');
  // }
  
  const {auth, logout, getOrders, orders} = props;

  const [dataOrders, setDataOrders ] = useState([]);

  useEffect(() => {
      if (dataOrders.length==0&&auth&&auth._id){
          getOrders(auth._id);
      }
      setDataOrders(orders);

  }, [auth, orders]);

  const [status, setStatus] = React.useState("1");

  const handleChange = event => {
    setStatus(event.target.value);
  };

  const rows = dataOrders;
  const rowsNew = dataOrders.filter((order)=>order.status==="Оформлен");
  const rowsProgress = dataOrders.filter((order)=>order.status==="В работе");
  const rowsDone = dataOrders.filter((order)=>order.status==="Выполнен");
  const rowsDelete = dataOrders.filter((order)=>order.status==="Отменен");

  return (
    <div>
      <Header
        color="transparent"
        brand="A-PRIORI"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small 
                filter 
                image={
                  "https://ic.pics.livejournal.com/galeneastro/32190196/2011009/2011009_900.jpg"
                }
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>

          <div className={classes.container}>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div className={classes.name}>
                    <h3 className={classes.title} style={{color:'#fff'}}>СПИСОК ЗАКАЗОВ</h3>
                  </div>
                  <br/>
                      <div className={classes.title}>
                          <h3 className={classes.title}>Добро пожаловать администратор, {auth.name}</h3>
                          <h5>{auth.email}</h5>
                          <a href={'tel: '+ auth.phone}>{auth.phone}</a>
                      </div>
                </div>
              </GridItem>
            </GridContainer>

            <div className={classes.description}>
              <p>
                Здесь находится информармация о всех заказах
              </p>
            </div>
            <div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTabs
                    headerColor="primary"
                    tabs={[
                      {
                        tabName: "Все заказы",
                        tabContent: (
                          <div>
                           <TableOrder rows={rows}/>
                          </div>
                        )
                      },
                      {
                        tabName: "Новые",
                        tabContent: (
                            <TableOrder rows={rowsNew}/>
                        )
                      },
                      {
                        tabName: "В работе",
                        tabContent: (
                            <TableOrder rows={rowsProgress}/>
                        )
                      },
                      {
                        tabName: "Выполненные",
                        tabContent: (
                            <TableOrder rows={rowsDone}/>
                        )
                      },
                      {
                        tabName: "Удаленные",
                        tabContent: (
                          <TableOrder rows={rowsDelete}/>
                        )
                      }
                    ]}
                  />
                </GridItem>

              </GridContainer>
              
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                  <Button 
                      round 
                      color="rose" 
                      className={classes.navButton}
                      onClick={logout}
                  >
                    <ExitToAppIcon className={classes.icons} />
                    Выйти из профиля
                  </Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
       
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  orders:PropTypes.array
};

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  auth: state.Auth,
  orders: state.Orders.orders,
});


export default connect(
    mapStateToProps,
    { logout, getOrders:userOrders  }
    )(ProfilePage)
