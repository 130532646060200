import  axios from 'axios';
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    LOGIN_GOOGLE_SUCCESS,
    LOGIN_GOOGLE_FAIL,
    MESSAGE__FAIL,
    MESSAGE_SUCCESS,
    UPDATED_USER_PHONE,
    ERROR_USER_PHONE
} from "../actions/types";

//Load User
export const loadUser = () => async dispatch =>{

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    try {
        const res = await axios.get('https://api.a-priori.by/api/auth');
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
        
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        })
    }} else {
        dispatch({
            type: AUTH_ERROR
        })  
    } 
};

//Register User
export const register = ({ name, email, phone, password }) => async dispatch => {
    const config = {
        headers:{
            'Content-Type': 'application/json'
        }
    };
    
    const body = JSON.stringify({ name, email, phone, password });
    
    try {
        const res = await axios.post('https://api.a-priori.by/api/users', body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });
        
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;
        
        if (errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: REGISTER_FAIL
        });
    }
};

//Login User
export const login = ({ email, password }) => async dispatch => {
    const config = {
        headers:{
            'Content-Type': 'application/json'
        }
    };
    
    const body = JSON.stringify({ email, password });
    
    try {
        const res = await axios.post('https://api.a-priori.by/api/auth', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;
        
        if (errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: LOGIN_FAIL
        });
    }

};

//Logout User
export const logout = () =>  dispatch => {
  dispatch({ type: LOGOUT });  
};

//Send message
export const send = ({ name, email, phone, text }) => async dispatch => {
    const config = {
        headers:{
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ name, email, phone, text });

    try {
        const res = await axios.post('https://api.a-priori.by/api/send', body, config);

        dispatch({
            type: MESSAGE_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert('Ваше сообщение успешно отправлено!', 'success'));

    } catch (err) {
        const errors = err.response.data.errors;

        if (errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: MESSAGE__FAIL
        });
    }

};

//Sign in Google
export const signGoogle = ( googleData ) => async dispatch => {

    const config = {
        headers:{
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({
        token: googleData.tokenId
    } );

    try {
        const res = await axios.post('https://api.a-priori.by/api/auth/google', body, config);
        dispatch({
            type: LOGIN_GOOGLE_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: LOGIN_GOOGLE_FAIL
        });
    }
};

export const addPhone = ( phone, id ) => async dispatch => {
    const config = {
        headers:{
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ phone, id });

    try {
        const res = await axios.post('https://api.a-priori.by/api/users/phone', body, config);

        dispatch({
            type: UPDATED_USER_PHONE,
            payload: res.data
        });

    } catch (err) {
        const errors = err.response.data.errors;

        if (errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: ERROR_USER_PHONE
        });
    }
}