export const ALBUMS = [
  {type:"0",
    label: "Love story",
    cover: './albums/love-story-bg.jpg',
    background: './albums/love-story-bg.jpg',
    schemas:[
      {
        type:"cover",
        schema:"cover-love-story",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//1
        schema:"schema3",
        text:"Ты знаешь, как подарить мне счастье",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema4",
        text:"Мы можем ВСЕ, когда мы ВМЕСТЕ",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema2",
        text:"Самый лучший день - день с ТОБОЙ!",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema1",
        text:"Когда я с тобой, все остальное не имеет значения",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema3-vertical",
        text:"Мне очень ХОРОШО быть рядом с тобой",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema4-variable",
        text:"Спасибо, что ты есть у меня!",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema2-double",
        text:"Я твоя...   Я твой...",
        position:"center-bottom"
      }
    ]

  },
  {type:"1",
    label: "Свадьба",
    cover: 'white',
    background: 'white',
    schemas:[
      {
        type:"cover",
        schema:"cover-wedding",
        text:"Ваш текст",
        position:"right-top"
      },
      {
        type:"page",//1
        schema:"schema3",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema6",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema5",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema3",
        text:"Вместе навсегда!",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema4-vertical",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema8",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema4",
        text:"",
        position:"center-bottom"
      }
    ]
  },
  { type:"2",
    label: "Семья",
    cover: '#be8842',
    background: './albums/family-bg.jpg',
    schemas:[
      {
        type:"cover",
        schema:"cover-family",
        text:"Ваш текст",
        position:"left-top"
      },
      {
        type:"page",//1
        schema:"schema2",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema3",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema4",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema5",
        text:"",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema6",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema8",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema6",
        text:"",
        position:"center-bottom"
      }
    ]
  },
  { type:"3",
    label: "Дети",
    cover: './albums/children-cover.jpg',
    background: './albums/children-bg.jpg',
    schemas:[
      {
        type:"cover",
        schema:"cover-children",
        text:"Мой первый год",
        position:"right-bottom"
      },
      {
        type:"page",//1
        schema:"cover-children1",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema6",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema5",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema4",
        text:"",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema2",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema3",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema4",
        text:"",
        position:"center-bottom"
      }
    ]
  },
  { type:"4",
    label: "Путешествие",
    cover: './albums/cover-TRAVELING.jpg',
    background: 'white',
    schemas:[
      {
        type:"cover",
        schema:"cover-traveling",
        text:"Ваш текст",
        position:"right-top"
      },
      {
        type:"page",//1
        schema:"schema3",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema4",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema4",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema3",
        text:"",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema5",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema3",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema4",
        text:"",
        position:"center-bottom"
      }
    ]
  },
  // { type:"5",
  //   label: "выпускной",
  //   cover: './albums/school-cover.jpg',
  //   background: './albums/school-bg.jpg',
  //   schemas:[
  //     {
  //       type:"cover",
  //       schema:"cover5",
  //       text:"",
  //       position:"center-top"
  //     },
  //     {
  //       type:"page",//1
  //       schema:"schema4",
  //       text:"",
  //       position:"left-bottom"
  //     },
  //     {
  //       type:"page",//2
  //       schema:"schema6",
  //       text:"",
  //       position:"right-bottom"
  //     },
  //     {
  //       type:"page",//3
  //       schema:"schema5",
  //       text:"",
  //       position:"right-bottom"
  //     },
  //     {
  //       type:"page",//4
  //       schema:"schema4",
  //       text:"",
  //       position:"right-top"
  //     },{
  //       type:"page",//5
  //       schema:"schema2",
  //       text:"",
  //       position:"left-bottom"
  //     },{
  //       type:"page",//6
  //       schema:"schema3",
  //       text:"",
  //       position:"left-top"
  //     },{
  //       type:"page",//7
  //       schema:"schema4",
  //       text:"",
  //       position:"center-bottom"
  //     }
  //   ]
  // },
  { type:"6",
    label: "Портфолио",
    cover: './albums/portfolio-cover.jpg',
    background: 'white',
    schemas:[
      {
        type:"cover",
        schema:"cover-portfolio",
        text:"",
        position:"center-top"
      },
      {
        type:"page",//1
        schema:"schema2",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema2",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema2",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema2",
        text:"",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema2",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema2",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema2",
        text:"",
        position:"center-bottom"
      }
    ]
  },
  // {type:"7",
  //   label: "дембель",
  //   cover: './albums/dembel-cover.jpg',
  //   background: 'black',
  //   schemas:[
  //     {
  //       type:"cover",
  //       schema:"cover4",
  //       text:"",
  //       position:"center-top"
  //     },
  //     {
  //       type:"page",//1
  //       schema:"schema4",
  //       text:"",
  //       position:"left-bottom"
  //     },
  //     {
  //       type:"page",//2
  //       schema:"schema6",
  //       text:"",
  //       position:"right-bottom"
  //     },
  //     {
  //       type:"page",//3
  //       schema:"schema5",
  //       text:"",
  //       position:"right-bottom"
  //     },
  //     {
  //       type:"page",//4
  //       schema:"schema4",
  //       text:"",
  //       position:"right-top"
  //     },{
  //       type:"page",//5
  //       schema:"schema2",
  //       text:"",
  //       position:"left-bottom"
  //     },{
  //       type:"page",//6
  //       schema:"schema3",
  //       text:"",
  //       position:"left-top"
  //     },{
  //       type:"page",//7
  //       schema:"schema4",
  //       text:"",
  //       position:"center-bottom"
  //     }
  //   ]
  // },
  {type:"8",
    label: "Юбилей",
    cover: './albums/happy-cover.jpg',
    background: './albums/date-bg.jpg',
    schemas:[
      {
        type:"cover",
        schema:"cover-happy",
        text:"",
        position:"center-top"
      },
      {
        type:"page",//1
        schema:"schema4",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"schema6",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"schema5",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"schema4",
        text:"",
        position:"right-top"
      },{
        type:"page",//5
        schema:"schema2",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"schema3",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"schema4",
        text:"",
        position:"center-bottom"
      }
    ]
  },
  // {type:"9",
  //   label: "школьный альбом",
  //   cover: './albums/school-cover.jpg',
  //   background: './albums/school-bg.jpg',
  //   schemas:[
  //     {
  //       type:"cover",
  //       schema:"cover4",
  //       text:"",
  //       position:"center-top"
  //     },
  //     {
  //       type:"page",//1
  //       schema:"schema4",
  //       text:"",
  //       position:"left-bottom"
  //     },
  //     {
  //       type:"page",//2
  //       schema:"schema6",
  //       text:"",
  //       position:"right-bottom"
  //     },
  //     {
  //       type:"page",//3
  //       schema:"schema5",
  //       text:"",
  //       position:"right-bottom"
  //     },
  //     {
  //       type:"page",//4
  //       schema:"schema4",
  //       text:"",
  //       position:"right-top"
  //     },{
  //       type:"page",//5
  //       schema:"schema2",
  //       text:"",
  //       position:"left-bottom"
  //     },{
  //       type:"page",//6
  //       schema:"schema3",
  //       text:"",
  //       position:"left-top"
  //     },{
  //       type:"page",//7
  //       schema:"schema4",
  //       text:"",
  //       position:"center-bottom"
  //     }
  //   ]
  // },
  {type:"10",
    label: "Собственная тема",
    cover: "white",
    background: "white",
    schemas:[
      {
        type:"cover",
        schema:"zero",
        text:"Создайте собственную тему",
        position:"center-top"
      },
      {
        type:"page",//1
        schema:"zero",
        text:"",
        position:"left-bottom"
      },
      {
        type:"page",//2
        schema:"zero",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//3
        schema:"zero",
        text:"",
        position:"right-bottom"
      },
      {
        type:"page",//4
        schema:"zero",
        text:"",
        position:"right-top"
      },{
        type:"page",//5
        schema:"zero",
        text:"",
        position:"left-bottom"
      },{
        type:"page",//6
        schema:"zero",
        text:"",
        position:"left-top"
      },{
        type:"page",//7
        schema:"zero",
        text:"",
        position:"center-bottom"
      }
    ]
  },
];
