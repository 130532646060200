import {cardTitle, title} from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const teamStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "2rem",
    marginTop: "0",
    minHeight: "32px",
    fontSize: "32px",
    textDecoration: "none",
  },
  titleSpan: {
    color: "#e91e63",
    fontFamily: "'Libre Caslon Display', serif",
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  margin5: {
    margin: "5px"
  },
  box: {
    maxWidth: "950px",
    margin: " 0 auto"
  },
  boxBG: {
    width: "190px",
    height: "230px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "0 2px 12px rgba(233, 30, 99, 0.4)",
  }
};

export default teamStyle;
